import { Theme } from "@lookiero/aurora";
import { StyleSheet } from "react-native";

const { colorBgBase, space2, space12 } = Theme.get();

export const HEADER_HEIGHT = space12;

const style = StyleSheet.create({
  header: {
    alignItems: "center",
    backgroundColor: colorBgBase,
    flexDirection: "row",
    flexGrow: 0,
    flexWrap: "nowrap",
    justifyContent: "space-between",
    overflow: "visible",
    paddingHorizontal: space2,
    width: "100%",
  },
});

export { style };
