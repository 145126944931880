import { ButtonIcon } from "@lookiero/aurora";
import React, { FC } from "react";
import { Header } from "../Header";
import { ButtonIconPlaceholder } from "../buttonIconPlaceholder/ButtonIconPlaceholder";
import { Logo } from "../logo/Logo";

interface DefaultHeaderProps {
  readonly onPressMenu?: () => void;
}
const DefaultHeader: FC<DefaultHeaderProps> = ({ onPressMenu }) => (
  <Header testID="default-header">
    <Logo />
    <ButtonIconPlaceholder />
    <ButtonIcon name="menu" testID="menu-button-icon" onPress={onPressMenu} />
  </Header>
);

export { DefaultHeader };
