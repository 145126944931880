import React, { FC, useEffect, useRef } from "react";
import { generatePath, useMatch, useNavigate } from "react-router-native";
import { CheckoutStatus } from "../../../domain/checkout/model/checkout";
import { CheckoutItemStatus } from "../../../domain/checkoutItem/model/checkoutItem";
import { CheckoutItemProjection } from "../../../projection/checkoutItem/checkoutItem";
import { useLogger } from "../../../shared/logging/useLogger";
import { Spinner } from "../../../shared/ui/components/atoms/spinner/Spinner";
import { useStartCheckout } from "../../domain/checkout/react/useStartCheckout";
import { useViewFirstAvailableCheckoutByCustomerId } from "../../projection/checkout/react/useViewFirstAvailableCheckoutByCustomerId";
import { useViewIsSizeChangeEnabledByCheckoutId } from "../../projection/checkout/react/useViewIsSizeChangeEnabledByCheckoutId";
import { Routes } from "./routes";
import { useBasePath } from "./useBasePath";

interface CheckoutMiddlewareProps {
  readonly customerId: string;
  readonly onNotAccessible: () => void;
  readonly loader?: JSX.Element;
  readonly children: JSX.Element;
}

const CheckoutMiddleware: FC<CheckoutMiddlewareProps> = ({
  customerId,
  onNotAccessible,
  loader = <Spinner />,
  children,
}) => {
  const logger = useLogger();
  const basePath = useBasePath();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  navigateRef.current = navigate;

  const itemRouteMatch = useMatch(`${basePath}/${Routes.ITEM}`);
  const itemRouteMatchRef = useRef(itemRouteMatch);
  itemRouteMatchRef.current = itemRouteMatch;
  const itemDetailRouteMatch = useMatch(`${basePath}/${Routes.ITEM_DETAIL}`);
  const itemDetailRouteMatchRef = useRef(itemDetailRouteMatch);
  itemDetailRouteMatchRef.current = itemDetailRouteMatch;
  const summaryRouteMatch = useMatch(`${basePath}/${Routes.SUMMARY}`);
  const summaryRouteMatchRef = useRef(summaryRouteMatch);
  summaryRouteMatchRef.current = summaryRouteMatch;
  const summaryTabsRouteMatch = useMatch(`${basePath}/${Routes.SUMMARY}/${Routes.SUMMARY_TABS}`);
  const summaryTabsRouteMatchRef = useRef(summaryTabsRouteMatch);
  summaryTabsRouteMatchRef.current = summaryTabsRouteMatch;
  const checkoutRouteMatch = useMatch(`${basePath}/${Routes.CHECKOUT}`);
  const checkoutRouteMatchRef = useRef(checkoutRouteMatch);
  checkoutRouteMatchRef.current = checkoutRouteMatch;
  const feedbackRouteMatch = useMatch(`${basePath}/${Routes.FEEDBACK}`);
  const feedbackRouteMatchRef = useRef(feedbackRouteMatch);
  feedbackRouteMatchRef.current = feedbackRouteMatch;
  const checkoutPaymentRouteMatch = useMatch(`${basePath}/${Routes.CHECKOUT}/${Routes.CHECKOUT_PAYMENT}`);
  const checkoutPaymentRouteMatchRef = useRef(checkoutPaymentRouteMatch);
  checkoutPaymentRouteMatchRef.current = checkoutPaymentRouteMatch;

  const checkoutShown = useRef(false);
  checkoutShown.current = checkoutShown.current || (Boolean(checkoutRouteMatch) && !Boolean(checkoutPaymentRouteMatch));

  const [checkout] = useViewFirstAvailableCheckoutByCustomerId({ customerId });
  const checkoutItemsRef = useRef<CheckoutItemProjection[]>();

  /* This hook is mounted at this level, although not being used directly, for optimization (regarding cache) */
  useViewIsSizeChangeEnabledByCheckoutId({ checkoutId: checkout?.id });

  const [startCheckout] = useStartCheckout({ checkoutId: checkout?.id, logger });
  useEffect(() => {
    if (checkout?.id) {
      startCheckout();
    }
  }, [checkout?.id, startCheckout]);

  useEffect(() => {
    /* Navigate to the feedback if checkout is submitted */
    if (checkout?.status === CheckoutStatus.SUBMITTED && !feedbackRouteMatchRef.current) {
      navigateRef.current(`${basePath}/${Routes.FEEDBACK}`, { replace: true });
    } else if (
      [CheckoutStatus.AVAILABLE, CheckoutStatus.STARTED, CheckoutStatus.NOTIFIED].includes(
        checkout?.status as CheckoutStatus,
      )
    ) {
      /**
       * If the customer has just reset an item's decission, navigate to that item.
       * Otherwise, navigate to the first item without customer decission.
       */
      const itemWithoutCustomerDecision =
        checkout?.items.find(
          (item) =>
            item.status === CheckoutItemStatus.INITIAL &&
            checkoutItemsRef.current?.find(
              (previousItem) => previousItem.id === item.id && previousItem.status !== CheckoutItemStatus.INITIAL,
            ),
        ) || checkout?.items.find((item) => item.status === CheckoutItemStatus.INITIAL);

      checkoutItemsRef.current = checkout?.items;

      /* Navigate to the summary if required */
      if (
        itemWithoutCustomerDecision === undefined &&
        !(
          summaryRouteMatchRef.current ||
          summaryTabsRouteMatchRef.current ||
          itemDetailRouteMatchRef.current ||
          checkoutRouteMatchRef.current ||
          checkoutPaymentRouteMatchRef.current
        )
      ) {
        navigateRef.current(`${basePath}/${Routes.SUMMARY}`, { replace: true });
      }

      if (
        itemWithoutCustomerDecision &&
        !(itemRouteMatchRef.current && itemRouteMatchRef.current.params.id === itemWithoutCustomerDecision.id)
      ) {
        navigateRef.current(generatePath(`${basePath}/${Routes.ITEM}`, { id: itemWithoutCustomerDecision.id }), {
          replace: true,
        });
      }
    }
  }, [basePath, checkout?.status, checkout?.items]);

  if (!checkout) {
    return loader;
  }

  /* Prevent direct payment access */
  if (checkoutPaymentRouteMatch && !checkoutShown.current) {
    onNotAccessible();

    return null;
  }

  return children;
};

export { CheckoutMiddleware };
