import React, { FC, useCallback, useMemo } from "react";
import { useLogger } from "../../../../logging/useLogger";
import { isModalNotification, isToastNotification } from "../../../projection/notification/notification";
import { useListNotifications } from "../../../projection/notification/react/useListNotifications";
import { useRemoveNotification } from "../../domain/notification/react/useRemoveNotification";
import { ModalNotifications } from "../components/modalNotifications/ModalNotifications";
import { ToastNotifications } from "../components/toastNotifications/ToastNotifications";

interface NotificationsProps {
  readonly contextId: string;
}

const Notifications: FC<NotificationsProps> = ({ contextId }) => {
  const logger = useLogger();
  const [notifications] = useListNotifications({ contextId });
  const [remove] = useRemoveNotification({ contextId, logger });

  const onRemove = useCallback((id: string) => remove({ aggregateId: id }), [remove]);

  const toastNotifications = useMemo(() => notifications?.filter(isToastNotification), [notifications]);
  const modalNotifications = useMemo(() => notifications?.filter(isModalNotification), [notifications]);

  return (
    <>
      <ToastNotifications notifications={toastNotifications} onRemove={onRemove} />
      <ModalNotifications notifications={modalNotifications} onRemove={onRemove} />
    </>
  );
};

export { Notifications };
