import { DomainEvent, MessageName } from "@lookiero/messaging";
import { NotificationRemoved, NOTIFICATION_REMOVED } from "../../../../domain/notification/model/notificationRemoved";
import { StorageNotificationsSaveFunction } from "./storageNotifications";

interface StorageNotificationsRemoveFunction extends StorageNotificationsSaveFunction {}

const isNotificationRemoved = (event: DomainEvent<MessageName>): event is NotificationRemoved =>
  event.name === NOTIFICATION_REMOVED;

const storageNotificationsRemove: StorageNotificationsRemoveFunction =
  ({ storage }) =>
  async (aggregateRoot) => {
    const notificationRemoved = aggregateRoot.domainEvents.find(isNotificationRemoved);

    if (!notificationRemoved) {
      return;
    }

    await storage.remove(aggregateRoot.aggregateId);
  };

export { storageNotificationsRemove };
