import { emitUserEvent as emit } from "@lookiero/user-tracking-front";
import { useCallback } from "react";
import { BaseTrackingEvent } from "../tracking";

interface EmitUserEventFunction<T extends BaseTrackingEvent> {
  (args: T): void;
}

const useEmitUserEvent = <T extends BaseTrackingEvent>(): EmitUserEventFunction<T> => {
  const emitUserEvent: EmitUserEventFunction<T> = useCallback((event) => emit(event), []);

  return emitUserEvent;
};

export { useEmitUserEvent };
