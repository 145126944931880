import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { PricingProjection } from "./pricing";

const VIEW_PRICING_BY_CHECKOUT_ID = "view_pricing_by_checkout_id";

interface ViewPricingByCheckoutIdPayload {
  readonly checkoutId: string;
}

interface ViewPricingByCheckoutId extends Query<typeof VIEW_PRICING_BY_CHECKOUT_ID>, ViewPricingByCheckoutIdPayload {}

interface ViewPricingByCheckoutIdFunction {
  (payload: ViewPricingByCheckoutIdPayload): ViewPricingByCheckoutId;
}

const viewPricingByCheckoutId: ViewPricingByCheckoutIdFunction = (payload) => ({
  ...query({ name: VIEW_PRICING_BY_CHECKOUT_ID }),
  ...payload,
});

type ViewPricingByCheckoutIdResult = PricingProjection | null;

interface PricingByCheckoutIdViewArgs extends CancelableQueryViewArgs {
  readonly checkoutId: string;
}

interface PricingByCheckoutIdView {
  (args: PricingByCheckoutIdViewArgs): Promise<ViewPricingByCheckoutIdResult>;
}

interface ViewPricingByCheckoutIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PricingByCheckoutIdView;
}

const viewPricingByCheckoutIdHandler: QueryHandlerFunction<
  ViewPricingByCheckoutId,
  ViewPricingByCheckoutIdResult,
  ViewPricingByCheckoutIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutId }) =>
    view({ checkoutId, signal });

export type { ViewPricingByCheckoutId, PricingByCheckoutIdView, ViewPricingByCheckoutIdResult };
export { VIEW_PRICING_BY_CHECKOUT_ID, viewPricingByCheckoutId, viewPricingByCheckoutIdHandler };
