export enum Routes {
  HOME = "",
  ITEM_DETAIL = "item/:id/detail",
  ITEM = "item/:id",
  SUMMARY = "summary",
  SUMMARY_TABS = "tabs/:tab",
  CHECKOUT = "checkout",
  CHECKOUT_PAYMENT = "payment",
  FEEDBACK = "feedback",
  RETURN = "return/:id",
}
