import React, { FC } from "react";
import { BootstrapFunctionArgs, bootstrap } from "../delivery/bootstrap";
import { inMemoryStorageNotifications } from "../persistence/inMemoryStorageNotifications";

interface NotificationsRootProps {
  readonly children: JSX.Element;
  readonly includeReactQueryDevTools?: boolean;
}

type NotificationsRoot = FC<NotificationsRootProps>;

interface NotificationsRootFunction {
  (args?: BootstrapFunctionArgs): NotificationsRoot;
}

const notificationsRoot: NotificationsRootFunction = (
  { storage } = {
    storage: inMemoryStorageNotifications(),
  },
) => {
  const { Component } = bootstrap({ storage });

  // eslint-disable-next-line react/display-name, react/prop-types
  return ({ children, includeReactQueryDevTools }) => (
    <Component includeReactQueryDevTools={includeReactQueryDevTools}>{children}</Component>
  );
};

export type { NotificationsRoot };
export { notificationsRoot };
