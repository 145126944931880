import { QueryStatus } from "@lookiero/messaging-react";
import React, { FC, useEffect, useRef } from "react";
import { Spinner } from "../../../shared/ui/components/atoms/spinner/Spinner";
import { useViewIsCheckoutAccessibleByCustomerId } from "../../projection/checkout/react/useViewIsCheckoutAccessibleByCustomerId";

interface CheckoutAccessibilityMiddlewareProps {
  readonly customerId: string | undefined;
  readonly onNotAccessible: () => void;
  readonly loader?: JSX.Element;
  readonly children: JSX.Element;
}

const CheckoutAccessibilityMiddleware: FC<CheckoutAccessibilityMiddlewareProps> = ({
  customerId,
  onNotAccessible,
  loader = <Spinner />,
  children,
}) => {
  const [accessible, status] = useViewIsCheckoutAccessibleByCustomerId({ customerId });

  const onNotAccessibleRef = useRef(onNotAccessible);
  onNotAccessibleRef.current = onNotAccessible;

  const notAccessible = accessible === false || status === QueryStatus.ERROR;

  useEffect(() => {
    if (notAccessible) {
      onNotAccessibleRef.current();
    }
  }, [notAccessible]);

  return accessible === undefined && [QueryStatus.IDLE, QueryStatus.LOADING].includes(status)
    ? loader
    : accessible
      ? children
      : null;
};

export { CheckoutAccessibilityMiddleware };
