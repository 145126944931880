import { command, Command } from "@lookiero/messaging";

const REPLACE_CHECKOUT_ITEM = "replace_checkout_item";

interface ReplaceCheckoutItemPayload {
  readonly aggregateId: string;
  readonly replacedForId: string;
}

interface ReplaceCheckoutItem extends Command<typeof REPLACE_CHECKOUT_ITEM>, ReplaceCheckoutItemPayload {}

interface ReplaceCheckoutItemFunction {
  (payload: ReplaceCheckoutItemPayload): ReplaceCheckoutItem;
}

const replaceCheckoutItem: ReplaceCheckoutItemFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: REPLACE_CHECKOUT_ITEM }),
  ...payload,
});

export type { ReplaceCheckoutItem };
export { REPLACE_CHECKOUT_ITEM, replaceCheckoutItem };
