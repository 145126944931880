import { HttpFunctionResult, HttpGetFunction, HttpPostFunction } from "./httpClient";

interface FetchHttpFunctionArgs {
  readonly apiUrl: () => string;
  readonly getAuthToken: () => Promise<string>;
}

interface FetchHttpPostFunction {
  (args: FetchHttpFunctionArgs): HttpPostFunction;
}

const fetchHttpPost: FetchHttpPostFunction =
  ({ apiUrl, getAuthToken }) =>
  async ({ endpoint, body, signal, result }) => {
    const response = await fetch(`${apiUrl()}${endpoint}`, {
      method: "POST",
      headers: {
        ["Content-Type"]: "application/json",
        authorization: `Bearer ${await getAuthToken()}`,
      },
      body: JSON.stringify(body),
      signal,
    });

    return await processResponse({ response, result });
  };

interface FetchHttpGetFunction {
  (args: FetchHttpFunctionArgs): HttpGetFunction;
}

const fetchHttpGet: FetchHttpGetFunction =
  ({ apiUrl, getAuthToken }) =>
  async ({ endpoint, signal, result }) => {
    const response = await fetch(`${apiUrl()}${endpoint}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${await getAuthToken()}`,
        ["Content-Type"]: "application/json",
      },
      referrer: "",
      signal,
    });

    return await processResponse({ response, result });
  };

interface ProcessResponseFunctionArgs<R, Result> {
  readonly result: HttpFunctionResult<R, Result> | undefined;
  readonly response: Response;
}

interface ProcessResponseFunction {
  <R, Result = R>(args: ProcessResponseFunctionArgs<R, Result>): Promise<Result> | never;
}

const processResponse: ProcessResponseFunction = async ({ response, result }) => {
  if (response.ok) {
    const responseText = await response.text();
    const responseResult = responseText ? JSON.parse(responseText) : responseText;

    return result?.success ? result?.success(responseResult) : responseResult;
  }

  if (result?.error !== undefined) {
    return result.error;
  }

  throw new Error(response.statusText);
};

export { fetchHttpGet, fetchHttpPost };
