import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_BOOKING_BOOKED = "checkout_booking_booked";

interface CheckoutBookingBookedPayload {
  readonly aggregateId: string;
  readonly checkoutItemId: string;
}

interface CheckoutBookingBooked extends DomainEvent<typeof CHECKOUT_BOOKING_BOOKED>, CheckoutBookingBookedPayload {}

interface CheckoutBookingBookedFunction {
  (payload: CheckoutBookingBookedPayload): CheckoutBookingBooked;
}

const checkoutBookingBooked: CheckoutBookingBookedFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: CHECKOUT_BOOKING_BOOKED }),
  ...payload,
});

const isCheckoutBookingBooked = (event: DomainEvent<MessageName>): event is CheckoutBookingBooked =>
  event.name === CHECKOUT_BOOKING_BOOKED;

export type { CheckoutBookingBooked };
export { CHECKOUT_BOOKING_BOOKED, checkoutBookingBooked, isCheckoutBookingBooked };
