import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CheckoutProjection } from "./checkout";

const VIEW_FIRST_AVAILABLE_CHECKOUT_BY_CUSTOMER_ID = "view_first_available_checkout_by_customer_id";

interface ViewFirstAvailableCheckoutByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewFirstAvailableCheckoutByCustomerId
  extends Query<typeof VIEW_FIRST_AVAILABLE_CHECKOUT_BY_CUSTOMER_ID>,
    ViewFirstAvailableCheckoutByCustomerIdPayload {}

interface ViewFirstAvailableCheckoutByCustomerIdFunction {
  (payload: ViewFirstAvailableCheckoutByCustomerIdPayload): ViewFirstAvailableCheckoutByCustomerId;
}

const viewFirstAvailableCheckoutByCustomerId: ViewFirstAvailableCheckoutByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_FIRST_AVAILABLE_CHECKOUT_BY_CUSTOMER_ID }),
  ...payload,
});

type ViewFirstAvailableCheckoutByCustomerIdResult = CheckoutProjection | null;

interface FirstAvailableCheckoutByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface FirstAvailableCheckoutByCustomerIdView {
  (args: FirstAvailableCheckoutByCustomerIdViewArgs): Promise<ViewFirstAvailableCheckoutByCustomerIdResult>;
}

interface ViewFirstAvailableCheckoutByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: FirstAvailableCheckoutByCustomerIdView;
}

const viewFirstAvailableCheckoutByCustomerIdHandler: QueryHandlerFunction<
  ViewFirstAvailableCheckoutByCustomerId,
  ViewFirstAvailableCheckoutByCustomerIdResult,
  ViewFirstAvailableCheckoutByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { FirstAvailableCheckoutByCustomerIdView, ViewFirstAvailableCheckoutByCustomerIdResult };
export {
  VIEW_FIRST_AVAILABLE_CHECKOUT_BY_CUSTOMER_ID,
  viewFirstAvailableCheckoutByCustomerId,
  viewFirstAvailableCheckoutByCustomerIdHandler,
};
