import {
  PricingByCheckoutIdView,
  ViewPricingByCheckoutIdResult,
} from "../../../projection/pricing/viewPricingByCheckoutId";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { PricingDto, toPricingProjection } from "./pricing";

interface HttpPricingByCheckoutIdView extends PricingByCheckoutIdView {}

interface HttpPricingByCheckoutIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpPricingByCheckoutIdViewFunction {
  (args: HttpPricingByCheckoutIdViewFunctionArgs): HttpPricingByCheckoutIdView;
}

interface ViewPricingByCheckoutIdResponse {
  readonly result: PricingDto;
}

const httpPricingByCheckoutIdView: HttpPricingByCheckoutIdViewFunction =
  ({ httpPost }) =>
  async ({ checkoutId, signal }) =>
    await httpPost<ViewPricingByCheckoutIdResponse, ViewPricingByCheckoutIdResult>({
      endpoint: "/view-pricing-by-checkout-id",
      body: { checkoutId },
      signal,
      result: {
        error: null,
        success: (response) => toPricingProjection(response.result),
      },
    });

export { httpPricingByCheckoutIdView };
