import { DomainEvent, MessageName } from "@lookiero/messaging";
import { Notification } from "../../../../domain/notification/model/notification";
import { NotificationCreated, NOTIFICATION_CREATED } from "../../../../domain/notification/model/notificationCreated";
import { NotificationDto } from "../../../persistence/notificationDto";
import { StorageNotificationsSaveFunction } from "./storageNotifications";

const fromDomain = ({
  aggregateId,
  type,
  level,
  titleI18nKey,
  bodyI18nKey,
  acceptI18nKey,
}: Notification): NotificationDto => ({
  aggregateId,
  type,
  level,
  titleI18nKey,
  bodyI18nKey,
  acceptI18nKey,
});

interface StorageNotificationsCreateFunction extends StorageNotificationsSaveFunction {}

const isNotificationCreated = (event: DomainEvent<MessageName>): event is NotificationCreated =>
  event.name === NOTIFICATION_CREATED;

const storageNotificationsCreate: StorageNotificationsCreateFunction =
  ({ storage }) =>
  async (aggregateRoot) => {
    const notificationCreated = aggregateRoot.domainEvents.find(isNotificationCreated);

    if (!notificationCreated) {
      return;
    }

    await storage.write(aggregateRoot.aggregateId, fromDomain(aggregateRoot));
  };

export { storageNotificationsCreate, fromDomain };
