import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isCheckoutItemKept } from "../../../../domain/checkoutItem/model/checkoutItemKept";
import { isCheckoutItemReplaced } from "../../../../domain/checkoutItem/model/checkoutItemReplaced";
import { isCheckoutItemReset } from "../../../../domain/checkoutItem/model/checkoutItemReset";
import { isCheckoutItemReturned } from "../../../../domain/checkoutItem/model/checkoutItemReturned";
import { PaymentFlowPayloadProjection } from "../../../../projection/payment/paymentFlowPayload";
import { viewPaymentFlowPayloadByCheckoutId } from "../../../../projection/payment/viewPaymentFlowPayloadByCheckoutId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewPaymentFlowPayloadByCheckoutIdFunctionArgs {
  readonly checkoutId?: string;
}

interface UseViewPaymentFlowPayloadByCheckoutIdFunction {
  (args: UseViewPaymentFlowPayloadByCheckoutIdFunctionArgs): UseQueryFunctionResult<PaymentFlowPayloadProjection>;
}

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isCheckoutItemKept(event) ||
  isCheckoutItemReplaced(event) ||
  isCheckoutItemReturned(event) ||
  isCheckoutItemReset(event);

const useViewPaymentFlowPayloadByCheckoutId: UseViewPaymentFlowPayloadByCheckoutIdFunction = ({ checkoutId }) =>
  useQuery({
    query: viewPaymentFlowPayloadByCheckoutId({ checkoutId: checkoutId as string }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: { refetchOnMount: "always", enabled: Boolean(checkoutId), cacheTime: 0 },
  });

export { useViewPaymentFlowPayloadByCheckoutId };
