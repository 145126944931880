import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

type IsSizeChangeEnabledByCheckoutIdProjection = boolean;

const VIEW_IS_SIZE_CHANGE_ENABLED_BY_CHECKOUT_ID = "view_is_size_change_enabled_by_checkout_id";

interface ViewIsSizeChangeEnabledByCheckoutIdPayload {
  readonly checkoutId: string;
}

interface ViewIsSizeChangeEnabledByCheckoutId
  extends Query<typeof VIEW_IS_SIZE_CHANGE_ENABLED_BY_CHECKOUT_ID>,
    ViewIsSizeChangeEnabledByCheckoutIdPayload {}

interface ViewIsSizeChangeEnabledByCheckoutIdFunction {
  (payload: ViewIsSizeChangeEnabledByCheckoutIdPayload): ViewIsSizeChangeEnabledByCheckoutId;
}

const viewIsSizeChangeEnabledByCheckoutId: ViewIsSizeChangeEnabledByCheckoutIdFunction = (payload) => ({
  ...query({ name: VIEW_IS_SIZE_CHANGE_ENABLED_BY_CHECKOUT_ID }),
  ...payload,
});

interface IsSizeChangeEnabledByCheckoutIdViewArgs extends CancelableQueryViewArgs {
  readonly checkoutId: string;
}

interface IsSizeChangeEnabledByCheckoutIdView {
  (args: IsSizeChangeEnabledByCheckoutIdViewArgs): Promise<IsSizeChangeEnabledByCheckoutIdProjection>;
}

interface IsSizeChangeEnabledByCheckoutIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: IsSizeChangeEnabledByCheckoutIdView;
}

const viewIsSizeChangeEnabledByCheckoutIdHandler: QueryHandlerFunction<
  ViewIsSizeChangeEnabledByCheckoutId,
  IsSizeChangeEnabledByCheckoutIdProjection,
  IsSizeChangeEnabledByCheckoutIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutId }) =>
    view({ checkoutId, signal });

export type { IsSizeChangeEnabledByCheckoutIdProjection, IsSizeChangeEnabledByCheckoutIdView };
export {
  VIEW_IS_SIZE_CHANGE_ENABLED_BY_CHECKOUT_ID,
  viewIsSizeChangeEnabledByCheckoutId,
  viewIsSizeChangeEnabledByCheckoutIdHandler,
};
