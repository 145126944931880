import { useDevice } from "@lookiero/aurora";

type ScreenSize = "S" | "M" | "L";

const useScreenSize = (): ScreenSize => {
  const { screen } = useDevice();

  const screenSize = Object.entries(screen)
    .filter(([key]) => ["S", "M", "L"].includes(key))
    .find(([, value]) => value);

  return screenSize?.[0] as ScreenSize;
};

export type { ScreenSize };
export { useScreenSize };
