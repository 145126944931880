import { DomainEvent, MessageName } from "@lookiero/messaging";
import { CheckoutItemReset, CHECKOUT_ITEM_RESET } from "../../../../domain/checkoutItem/model/checkoutItemReset";
import { HttpCheckoutItemsSaveFunction } from "./httpCheckoutItems";

interface HttpCheckoutItemsResetFunction extends HttpCheckoutItemsSaveFunction {}

const isCheckoutItemReset = (event: DomainEvent<MessageName>): event is CheckoutItemReset =>
  event.name === CHECKOUT_ITEM_RESET;

const httpCheckoutItemsReset: HttpCheckoutItemsResetFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const checkoutItemReset = domainEvents.find(isCheckoutItemReset);

    if (!checkoutItemReset) {
      return;
    }

    await httpPost({
      endpoint: "/reset-checkout-item",
      body: { checkoutItemId: aggregateId },
    });
  };

export { httpCheckoutItemsReset };
