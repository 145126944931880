import { Theme } from "@lookiero/aurora";
import { Platform, StyleSheet, ViewStyle } from "react-native";

const { space4 } = Theme.get();

const style = StyleSheet.create({
  notifications: {
    left: 0,
    ...(Platform.select({
      web: { position: "fixed" },
      ios: { position: "absolute" },
      android: { position: "absolute" },
    }) as ViewStyle),
    flexDirection: "column",
    top: 0,
    width: "100%",
    zIndex: 20,
  },

  row: {
    flex: 1,
    justifyContent: "center",
  },
  rowSmall: {
    paddingLeft: space4,
    paddingRight: space4,
  },
  toast: {
    marginTop: space4,
  },
});

export { style };
