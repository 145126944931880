import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import {
  CheckoutFeedbacksGetFunction,
  CheckoutFeedbacksSaveFunction,
} from "../../../../domain/checkoutFeedback/model/checkoutFeedbacks";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpCheckoutFeedbacksGive } from "./httpCheckoutFeedbacksGive";

interface HttpCheckoutFeedbacksGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpCheckoutFeedbacksGetFunction extends CheckoutFeedbacksGetFunction<HttpCheckoutFeedbacksGetFunctionArgs> {}

const getCheckoutFeedback: HttpCheckoutFeedbacksGetFunction = () => async () => {
  throw new Error("There is no equivalent AggregateRoot in the backend");
};

interface HttpCheckoutFeedbacksSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutFeedbacksSaveFunction
  extends CheckoutFeedbacksSaveFunction<HttpCheckoutFeedbacksSaveFunctionArgs> {}

const saveCheckoutFeedback: HttpCheckoutFeedbacksSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) =>
    await httpCheckoutFeedbacksGive({ httpPost })(aggregateRoot);

export type { HttpCheckoutFeedbacksSaveFunction };
export { getCheckoutFeedback, saveCheckoutFeedback };
