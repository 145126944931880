import { animated, useTransition } from "@react-spring/native";
import React, { FC, ReactElement, useCallback, useState } from "react";
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from "react-native";
import { style } from "./Stack.style";

interface StackProps {
  readonly children: ReactElement | ReactElement[];
  readonly style?: StyleProp<ViewStyle>;
}

const Stack: FC<StackProps> = ({ children, style: customStyle }: StackProps) => {
  const [heights, setHeights] = useState<Record<string, number>>({});
  const handleOnLayout = useCallback(
    (itemKey: string) =>
      ({ nativeEvent: { layout } }: LayoutChangeEvent) =>
        setHeights((heights) => ({ ...heights, [itemKey]: layout.height })),
    [],
  );

  const inState = useCallback((height: number | undefined) => ({ opacity: 1, height, translateY: 0 }), []);

  const transitions = useTransition(children, {
    keys: (item) => `${item.key}`,
    native: true,
    from: { opacity: 0, height: undefined, translateY: -50 },
    enter: (item) => inState(heights[item.key as string]),
    update: (item) => inState(heights[item.key as string]),
    leave: { opacity: 0, height: 0, translateY: -50 },
  });

  return (
    <View pointerEvents="box-none" style={[style.stack, customStyle]}>
      {transitions(({ opacity, height, translateY }, item, { phase }) => (
        <animated.View
          key={item.key}
          testID="stack-item"
          style={[
            {
              zIndex: phase === "leave" ? 0 : 10,
              opacity,
              height,
              transform: [{ translateY }],
            },
            style.stackItem,
          ]}
        >
          <View onLayout={handleOnLayout(item.key as string)}>{item}</View>
        </animated.View>
      ))}
    </View>
  );
};

export { Stack };
