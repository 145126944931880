import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { PaymentFlowPayloadProjection } from "./paymentFlowPayload";

const VIEW_PAYMENT_FLOW_PAYLOAD_BY_CHECKOUT_ID = "view_payment_flow_payload_by_checkout_id";

interface ViewPaymentFlowPayloadByCheckoutIdPayload {
  readonly checkoutId: string;
}

interface ViewPaymentFlowPayloadByCheckoutId
  extends Query<typeof VIEW_PAYMENT_FLOW_PAYLOAD_BY_CHECKOUT_ID>,
    ViewPaymentFlowPayloadByCheckoutIdPayload {}

interface ViewPaymentFlowPayloadByCheckoutIdFunction {
  (payload: ViewPaymentFlowPayloadByCheckoutIdPayload): ViewPaymentFlowPayloadByCheckoutId;
}

const viewPaymentFlowPayloadByCheckoutId: ViewPaymentFlowPayloadByCheckoutIdFunction = (payload) => ({
  ...query({ name: VIEW_PAYMENT_FLOW_PAYLOAD_BY_CHECKOUT_ID }),
  ...payload,
});

type ViewPaymentFlowPayloadByCheckoutIdResult = PaymentFlowPayloadProjection | null;

interface PaymentFlowPayloadByCheckoutIdViewArgs extends CancelableQueryViewArgs {
  readonly checkoutId: string;
}

interface PaymentFlowPayloadByCheckoutIdView {
  (args: PaymentFlowPayloadByCheckoutIdViewArgs): Promise<ViewPaymentFlowPayloadByCheckoutIdResult>;
}

interface ViewPaymentFlowPayloadByCheckoutIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: PaymentFlowPayloadByCheckoutIdView;
}

const viewPaymentFlowPayloadByCheckoutIdHandler: QueryHandlerFunction<
  ViewPaymentFlowPayloadByCheckoutId,
  ViewPaymentFlowPayloadByCheckoutIdResult,
  ViewPaymentFlowPayloadByCheckoutIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutId }) =>
    view({ checkoutId, signal });

export type {
  ViewPaymentFlowPayloadByCheckoutId,
  PaymentFlowPayloadByCheckoutIdView,
  ViewPaymentFlowPayloadByCheckoutIdResult,
};
export {
  VIEW_PAYMENT_FLOW_PAYLOAD_BY_CHECKOUT_ID,
  viewPaymentFlowPayloadByCheckoutId,
  viewPaymentFlowPayloadByCheckoutIdHandler,
};
