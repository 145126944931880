import { NotificationDto } from "./notificationDto";
import { Storage } from "./storage";

interface InMemoryStorageNotificationsFunction {
  (): Storage<NotificationDto>;
}

const inMemoryStorageNotifications: InMemoryStorageNotificationsFunction = () => {
  const storage: Record<string, NotificationDto> = {};

  const read = async (key: string) => storage[key] || null;

  const list = async () => Object.values(storage);

  const write = async (key: string, value: NotificationDto) => {
    storage[key] = value;
  };

  const remove = async (key: string) => {
    delete storage[key];
  };

  return { read, list, write, remove };
};

export { inMemoryStorageNotifications };
