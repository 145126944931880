import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isCheckoutStarted } from "../../../../domain/checkout/model/checkoutStarted";
import { isCheckoutSubmitted } from "../../../../domain/checkout/model/checkoutSubmitted";
import { isCheckoutBookingBooked } from "../../../../domain/checkoutBooking/model/checkoutBookingBooked";
import { isCheckoutBookingExpired } from "../../../../domain/checkoutBooking/model/checkoutBookingExpired";
import { isCheckoutFeedbackGiven } from "../../../../domain/checkoutFeedback/model/checkoutFeedbackGiven";
import { isCheckoutItemKept } from "../../../../domain/checkoutItem/model/checkoutItemKept";
import { isCheckoutItemReplaced } from "../../../../domain/checkoutItem/model/checkoutItemReplaced";
import { isCheckoutItemReset } from "../../../../domain/checkoutItem/model/checkoutItemReset";
import { isCheckoutItemReturned } from "../../../../domain/checkoutItem/model/checkoutItemReturned";
import { CheckoutProjection } from "../../../../projection/checkout/checkout";
import { viewFirstAvailableCheckoutByCustomerId } from "../../../../projection/checkout/viewFirstAvailableCheckoutByCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isCheckoutItemKept(event) ||
  isCheckoutItemReplaced(event) ||
  isCheckoutItemReturned(event) ||
  isCheckoutItemReset(event) ||
  isCheckoutStarted(event) ||
  isCheckoutSubmitted(event) ||
  isCheckoutFeedbackGiven(event) ||
  isCheckoutBookingBooked(event) ||
  isCheckoutBookingExpired(event);

interface UseViewFirstAvailableCheckoutByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseViewFirstAvailableCheckoutByCustomerIdFunction {
  (args: UseViewFirstAvailableCheckoutByCustomerIdFunctionArgs): UseQueryFunctionResult<CheckoutProjection>;
}

const useViewFirstAvailableCheckoutByCustomerId: UseViewFirstAvailableCheckoutByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: viewFirstAvailableCheckoutByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      refetchOnMount: "always",
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  });

export { useViewFirstAvailableCheckoutByCustomerId };
