import { CommandStatus } from "@lookiero/messaging-react";
import { PaymentFlowRef } from "@lookiero/payments-front";
import { LegacyBoxCheckout } from "@lookiero/payments-front/build/components/PaymentFlow/internals/strategies/LegacyBoxCheckoutStrategy";
import { ChargeStatus } from "@lookiero/payments-front/build/infrastructure/CheckoutAPI";
import { RefObject, useCallback, useMemo, useState } from "react";
import { PaymentFlowPayloadProjection } from "../../../projection/payment/paymentFlowPayload";
import { Logger } from "../../../shared/logging/Logger";
import { useCreateToastNotification } from "../../../shared/notifications";
import { NotificationLevel } from "../../../shared/notifications/domain/notification/model/notification";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";
import { useSubmitCheckout as useSubmitCheckoutCommand } from "../../domain/checkout/react/useSubmitCheckout";
import { useBlockCheckoutBooking } from "../../domain/checkoutBooking/react/useBlockCheckoutBooking";
import { I18nMessages } from "../i18n/i18n";

type Status = "idle" | "loading" | "success" | "error";

interface SubmitCheckoutFunctionArgs {
  readonly paymentFlowPayload: PaymentFlowPayloadProjection;
  readonly sizeChangeEnabled: boolean;
}

interface SubmitCheckoutFunction {
  (args: SubmitCheckoutFunctionArgs): Promise<void>;
}

type UseSubmitCheckoutResult = [submitCheckout: SubmitCheckoutFunction, status: Status];

interface UseSubmitCheckoutFunctionArgs {
  readonly checkoutId: string;
  readonly checkoutBookingId: string;
  readonly paymentFlowRef: RefObject<PaymentFlowRef>;
  readonly onError: () => void;
  readonly onSuccess?: () => void;
  readonly logger: Logger;
}

interface UseSubmitCheckoutFunction {
  (args: UseSubmitCheckoutFunctionArgs): UseSubmitCheckoutResult;
}

const useSubmitCheckout: UseSubmitCheckoutFunction = ({
  checkoutId,
  checkoutBookingId,
  paymentFlowRef,
  onError,
  onSuccess,
  logger,
}) => {
  const [submitCheckoutCommand, submitCheckoutCommandStatus] = useSubmitCheckoutCommand({ checkoutId, logger });
  const [blockCheckoutBooking, blockCheckoutBookingStatus] = useBlockCheckoutBooking({ checkoutBookingId, logger });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const [startLegacyBoxCheckoutStatus, setStartLegacyBoxCheckoutStatus] = useState<Status>("idle");

  const submitCheckout: SubmitCheckoutFunction = useCallback(
    async ({ paymentFlowPayload, sizeChangeEnabled }) => {
      try {
        sizeChangeEnabled && (await blockCheckoutBooking());
      } catch (error) {
        return;
      }

      paymentFlowRef.current?.startLegacyBoxCheckout(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        paymentFlowPayload,
        async ({ status, toaster, final }: LegacyBoxCheckout) => {
          setStartLegacyBoxCheckoutStatus("loading");

          if (final) {
            if (status === ChargeStatus.EXECUTED) {
              setStartLegacyBoxCheckoutStatus("success");
              await submitCheckoutCommand();
              onSuccess?.();
            } else {
              createNotification({
                level: NotificationLevel.ERROR,
                bodyI18nKey: toaster?.id || I18nMessages.CHECKOUT_TOAST_PAYMENT_ERROR,
              });
              setStartLegacyBoxCheckoutStatus("error");
            }
          }
        },
      );
    },
    [paymentFlowRef, blockCheckoutBooking, submitCheckoutCommand, onSuccess, createNotification],
  );

  const status: Status = useMemo(() => {
    if (
      blockCheckoutBookingStatus === CommandStatus.LOADING ||
      startLegacyBoxCheckoutStatus === "loading" ||
      submitCheckoutCommandStatus === CommandStatus.LOADING
    ) {
      return "loading";
    }
    if (
      blockCheckoutBookingStatus === CommandStatus.SUCCESS &&
      startLegacyBoxCheckoutStatus === "success" &&
      submitCheckoutCommandStatus === CommandStatus.SUCCESS
    ) {
      return "success";
    }
    if (
      blockCheckoutBookingStatus === CommandStatus.ERROR ||
      startLegacyBoxCheckoutStatus === "error" ||
      submitCheckoutCommandStatus === CommandStatus.ERROR
    ) {
      onError();
      return "error";
    }

    return "idle";
  }, [blockCheckoutBookingStatus, submitCheckoutCommandStatus, onError, startLegacyBoxCheckoutStatus]);

  return [submitCheckout, status];
};

export type { Status, SubmitCheckoutFunction };
export { useSubmitCheckout };
