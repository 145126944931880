import { command, Command } from "@lookiero/messaging";
import { Feedbacks } from "../model/feedbacks";

const RETURN_CHECKOUT_ITEM = "return_checkout_item";

interface ReturnCheckoutItemPayload {
  readonly aggregateId: string;
  readonly feedbacks: Feedbacks;
}

interface ReturnCheckoutItem extends Command<typeof RETURN_CHECKOUT_ITEM>, ReturnCheckoutItemPayload {}

interface ReturnCheckoutItemFunction {
  (payload: ReturnCheckoutItemPayload): ReturnCheckoutItem;
}

const returnCheckoutItem: ReturnCheckoutItemFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: RETURN_CHECKOUT_ITEM }),
  ...payload,
});

export type { ReturnCheckoutItem };
export { RETURN_CHECKOUT_ITEM, returnCheckoutItem };
