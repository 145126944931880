import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { BlockCheckoutBooking } from "../command/blockCheckoutBooking";
import { BookCheckoutBookingForCheckoutItem } from "../command/bookCheckoutBookingForCheckoutItem";
import { checkoutBookingBlocked } from "./checkoutBookingBlocked";
import { checkoutBookingBooked } from "./checkoutBookingBooked";
import { checkoutBookingExpired } from "./checkoutBookingExpired";

interface CheckoutBooking extends AggregateRoot {
  readonly checkoutItemIds: string[];
  readonly isExpired: boolean;
}

const bookCheckoutBookingForCheckoutItemHandler: CommandHandlerFunction<
  BookCheckoutBookingForCheckoutItem,
  CheckoutBooking
> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, checkoutItemId } = command;

    return {
      ...aggregateRoot,
      domainEvents: [checkoutBookingBooked({ aggregateId, checkoutItemId })],
    };
  };

const blockCheckoutBookingHandler: CommandHandlerFunction<BlockCheckoutBooking, CheckoutBooking> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;

    if (aggregateRoot.isExpired) {
      return {
        ...aggregateRoot,
        domainEvents: [checkoutBookingExpired({ aggregateId })],
      };
    }

    return {
      ...aggregateRoot,
      domainEvents: [checkoutBookingBlocked({ aggregateId })],
    };
  };

export type { CheckoutBooking };
export { bookCheckoutBookingForCheckoutItemHandler, blockCheckoutBookingHandler };
