import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ReturnQuestionProjection } from "./returnQuestion";

const LIST_RETURN_QUESTIONS_BY_CHECKOUT_ITEM_ID = "list_return_questions_by_checkout_item_id";

interface ListReturnQuestionsByCheckoutItemIdPayload {
  readonly checkoutItemId: string;
}

interface ListReturnQuestionsByCheckoutItemId
  extends Query<typeof LIST_RETURN_QUESTIONS_BY_CHECKOUT_ITEM_ID>,
    ListReturnQuestionsByCheckoutItemIdPayload {}

interface ListReturnQuestionsByCheckoutItemIdFunction {
  (payload: ListReturnQuestionsByCheckoutItemIdPayload): ListReturnQuestionsByCheckoutItemId;
}

const listReturnQuestionsByCheckoutItemId: ListReturnQuestionsByCheckoutItemIdFunction = (payload) => ({
  ...query({ name: LIST_RETURN_QUESTIONS_BY_CHECKOUT_ITEM_ID }),
  ...payload,
});

type ListReturnQuestionsByCheckoutItemIdResult = ReturnQuestionProjection[] | null;

interface ReturnQuestionsByCheckoutItemIdViewArgs extends CancelableQueryViewArgs {
  readonly checkoutItemId: string;
}

interface ReturnQuestionsByCheckoutItemIdView {
  (args: ReturnQuestionsByCheckoutItemIdViewArgs): Promise<ListReturnQuestionsByCheckoutItemIdResult>;
}

interface ListReturnQuestionsByCheckoutItemIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ReturnQuestionsByCheckoutItemIdView;
}

const listReturnQuestionsByCheckoutItemIdHandler: QueryHandlerFunction<
  ListReturnQuestionsByCheckoutItemId,
  ListReturnQuestionsByCheckoutItemIdResult,
  ListReturnQuestionsByCheckoutItemIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutItemId }) =>
    view({ checkoutItemId, signal });

export type {
  ListReturnQuestionsByCheckoutItemId,
  ReturnQuestionsByCheckoutItemIdView,
  ListReturnQuestionsByCheckoutItemIdResult,
};
export {
  LIST_RETURN_QUESTIONS_BY_CHECKOUT_ITEM_ID,
  listReturnQuestionsByCheckoutItemId,
  listReturnQuestionsByCheckoutItemIdHandler,
};
