import React, { FC, useMemo } from "react";
import { Platform, SafeAreaView, View, ViewProps } from "react-native";
import { style } from "./Header.style";

type HeaderProps = ViewProps;

const Header: FC<HeaderProps> = ({ children, style: customStyle, ...props }) => {
  const Component = useMemo(() => (Platform.OS === "android" ? SafeAreaView : View), []);

  return (
    <Component {...props} style={[style.header, customStyle]}>
      {children}
    </Component>
  );
};

export { Header };
