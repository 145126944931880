import { NotificationType } from "../../../domain/notification/model/notification";
import {
  ModalNotificationProjection,
  ToastNotificationProjection,
} from "../../../projection/notification/notification";
import { NotificationDto } from "../../persistence/notificationDto";

interface ToNotificationProjectionFunction {
  (notificationDto: NotificationDto): ToastNotificationProjection | ModalNotificationProjection;
}

const toNotificationProjection: ToNotificationProjectionFunction = (notificationDto) =>
  notificationDto.type === NotificationType.TOAST
    ? {
        id: notificationDto.aggregateId,
        type: notificationDto.type,
        level: notificationDto.level,
        titleI18nKey: notificationDto.titleI18nKey,
        bodyI18nKey: notificationDto.bodyI18nKey,
      }
    : {
        id: notificationDto.aggregateId,
        type: notificationDto.type,
        titleI18nKey: notificationDto.titleI18nKey as string,
        bodyI18nKey: notificationDto.bodyI18nKey,
        acceptI18nKey: notificationDto.acceptI18nKey as string,
      };

export { toNotificationProjection };
