import { QueryStatus } from "@lookiero/messaging-react";
import { PaymentFlow, PaymentFlowRef } from "@lookiero/payments-front";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-native";
import { CheckoutItemStatus } from "../../../../../../domain/checkoutItem/model/checkoutItem";
import { Currency } from "../../../../../../domain/checkoutItem/model/currency";
import { Country } from "../../../../../../projection/shared/country";
import { Subscription } from "../../../../../../projection/shared/subscription";
import { useLogger } from "../../../../../../shared/logging/useLogger";
import { useTrackCheckout } from "../../../../../../shared/tracking/infrastructure/useTrackCheckout";
import { useViewFirstAvailableCheckoutByCustomerId } from "../../../../../projection/checkout/react/useViewFirstAvailableCheckoutByCustomerId";
import { useViewIsSizeChangeEnabledByCheckoutId } from "../../../../../projection/checkout/react/useViewIsSizeChangeEnabledByCheckoutId";
import { useViewPaymentFlowPayloadByCheckoutId } from "../../../../../projection/payment/react/useViewPaymentFlowPayloadByCheckoutId";
import { useViewPricingByCheckoutId } from "../../../../../projection/pricing/react/useViewPricingByCheckoutId";
import { TrackingPage } from "../../../../../tracking/tracking";
import { useSubmitCheckout } from "../../../../hooks/useSubmitCheckout";
import { Routes } from "../../../../routing/routes";
import { useBasePath } from "../../../../routing/useBasePath";

interface CheckoutPaymentModalProps {
  readonly customerId: string;
  readonly country: Country;
  readonly coupon: string | null;
  readonly orderNumber: number;
  readonly isFirstOrder: boolean;
  readonly subscription: Subscription;
  readonly getAuthToken: () => Promise<string>;
  readonly onCheckoutSubmitted?: () => void;
}
const CheckoutPaymentModal: FC<CheckoutPaymentModalProps> = ({
  customerId,
  country,
  coupon,
  isFirstOrder,
  subscription,
  orderNumber,
  getAuthToken,
  onCheckoutSubmitted,
}) => {
  const paymentFlowRef = useRef<PaymentFlowRef>(null);
  const logger = useLogger();

  const [checkout, checkoutStatus] = useViewFirstAvailableCheckoutByCustomerId({ customerId });
  const [paymentFlowPayload] = useViewPaymentFlowPayloadByCheckoutId({
    checkoutId: checkout?.id as string,
  });
  const [sizeChangeEnabled] = useViewIsSizeChangeEnabledByCheckoutId({ checkoutId: checkout?.id as string });
  const [pricing] = useViewPricingByCheckoutId({
    checkoutId: checkout?.id as string,
    queryOptions: { refetchOnMount: true },
  });

  const [authToken, setAuthToken] = useState<string>();
  useEffect(() => {
    const loadAuthToken = async () => setAuthToken(await getAuthToken());
    loadAuthToken();
  }, [getAuthToken]);

  const basePath = useBasePath();
  const navigate = useNavigate();
  const trackCheckout = useTrackCheckout({
    checkoutId: checkout?.id as string,
    country,
    page: TrackingPage.CHECKOUT,
  });

  const handleOnSubmitCheckoutError = useCallback(
    () => navigate(`${basePath}/${Routes.CHECKOUT}`, { replace: true }),
    [basePath, navigate],
  );
  const handleOnSubmitCheckoutSuccess = useCallback(() => {
    const checkoutItemsKept = checkout?.items.filter(
      (checkoutItem) =>
        checkoutItem.status === CheckoutItemStatus.KEPT || checkoutItem.status === CheckoutItemStatus.REPLACED,
    );
    const totalReplacedFor = checkoutItemsKept?.filter(({ replacedFor }) => Boolean(replacedFor)).length || 0;

    trackCheckout({
      totalCheckoutItemsKept: checkoutItemsKept?.length || 0,
      pendingToPay: (pricing?.pendingToPay.amount as number) / 100,
      coupon,
      currencyCode: pricing?.pendingToPay.currency as Currency,
      isFirstOrder,
      orderNumber,
      totalReplacedFor,
      subscription,
      userId: customerId,
    });
    onCheckoutSubmitted?.();
  }, [
    checkout?.items,
    coupon,
    customerId,
    isFirstOrder,
    onCheckoutSubmitted,
    orderNumber,
    pricing?.pendingToPay.amount,
    pricing?.pendingToPay.currency,
    subscription,
    trackCheckout,
  ]);
  const [submitCheckout] = useSubmitCheckout({
    checkoutId: checkout?.id as string,
    checkoutBookingId: checkout?.checkoutBookingId as string,
    paymentFlowRef,
    onError: handleOnSubmitCheckoutError,
    onSuccess: handleOnSubmitCheckoutSuccess,
    logger,
  });
  useEffect(() => {
    if (paymentFlowPayload && sizeChangeEnabled !== undefined && pricing !== undefined) {
      submitCheckout({ paymentFlowPayload, sizeChangeEnabled });
    }
  }, [paymentFlowPayload, pricing, sizeChangeEnabled, submitCheckout]);

  const dependenciesLoadedStatuses = [QueryStatus.ERROR, QueryStatus.SUCCESS];
  const dependenciesLoaded = (dependenciesLoadedStatuses.includes(checkoutStatus) || checkout) && authToken;

  if (!dependenciesLoaded) return null;

  return <PaymentFlow ref={paymentFlowRef} token={authToken} />;
};

export { CheckoutPaymentModal };
