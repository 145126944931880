import { DomainEvent, MessageName } from "@lookiero/messaging";
import {
  CheckoutBookingBlocked,
  CHECKOUT_BOOKING_BLOCKED,
} from "../../../../domain/checkoutBooking/model/checkoutBookingBlocked";
import { HttpCheckoutBookingsSaveFunction } from "./httpCheckoutBookings";

interface HttpCheckoutBookingsBlockFunction extends HttpCheckoutBookingsSaveFunction {}

const isCheckoutBookingBlocked = (event: DomainEvent<MessageName>): event is CheckoutBookingBlocked =>
  event.name === CHECKOUT_BOOKING_BLOCKED;

const httpCheckoutBookingsBlock: HttpCheckoutBookingsBlockFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const checkoutBookingBlocked = domainEvents.find(isCheckoutBookingBlocked);

    if (!checkoutBookingBlocked) {
      return;
    }

    await httpPost({
      endpoint: "/block-checkout-booking",
      body: {
        checkoutBookingId: aggregateId,
      },
    });
  };

export { httpCheckoutBookingsBlock };
