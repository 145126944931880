import {
  CheckoutQuestionsByCheckoutIdView,
  ListCheckoutQuestionsByCheckoutIdResult,
} from "../../../projection/checkoutQuestion/listCheckoutQuestionsByCheckoutId";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpCheckoutQuestionsByCheckoutIdView extends CheckoutQuestionsByCheckoutIdView {}

interface HttpCheckoutQuestionsByCheckoutIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutQuestionsByCheckoutIdViewFunction {
  (args: HttpCheckoutQuestionsByCheckoutIdViewFunctionArgs): HttpCheckoutQuestionsByCheckoutIdView;
}

interface ListCheckoutQuestionsByCheckoutIdResponse {
  readonly result: ListCheckoutQuestionsByCheckoutIdResult;
}

const httpCheckoutQuestionsByCheckoutIdView: HttpCheckoutQuestionsByCheckoutIdViewFunction =
  ({ httpPost }) =>
  async ({ checkoutId, signal }) =>
    await httpPost<ListCheckoutQuestionsByCheckoutIdResponse, ListCheckoutQuestionsByCheckoutIdResult>({
      endpoint: "/list-checkout-questions-by-checkout-id",
      body: { checkoutId },
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpCheckoutQuestionsByCheckoutIdView };
