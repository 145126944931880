import { COLOR, Notification, NOTIFICATION_VARIANT, Text } from "@lookiero/aurora";
import { useI18nMessage, useIntl } from "@lookiero/i18n-react";
import React, { FC, useMemo, useCallback } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { NotificationLevel } from "../../../../domain/notification/model/notification";
import { ToastNotificationProjection } from "../../../../projection/notification/notification";

const toastVariantForNotificationLevel: Record<NotificationLevel, NOTIFICATION_VARIANT> = {
  [NotificationLevel.INFO]: NOTIFICATION_VARIANT.WARNING,
  [NotificationLevel.ERROR]: NOTIFICATION_VARIANT.CRITICAL,
  [NotificationLevel.SUCCESS]: NOTIFICATION_VARIANT.SUCCESS,
};

interface ToastStyle {
  readonly toast?: StyleProp<ViewStyle>;
}

interface ToastNotificationItemProps {
  readonly notification: ToastNotificationProjection;
  readonly onRemove: (notificationId: string) => void;
  readonly autoHideTimeout?: number;
  readonly testID?: string;
  readonly style?: ToastStyle;
}

const ToastNotificationItem: FC<ToastNotificationItemProps> = ({
  notification,
  onRemove,
  autoHideTimeout,
  testID = "toast-notification-item",
  style: customStyle,
}) => {
  const { formatMessage } = useIntl();
  const title = useMemo(
    () => (notification.titleI18nKey ? formatMessage({ id: notification.titleI18nKey }) : undefined),
    [formatMessage, notification.titleI18nKey],
  );

  const body = useI18nMessage({ id: notification.bodyI18nKey });
  const handleOnClose = useCallback(() => onRemove(notification.id), [notification.id, onRemove]);

  return (
    <Notification
      nativeID={testID}
      style={customStyle?.toast}
      testID={testID}
      text={title ? title : body}
      timeoutClose={autoHideTimeout}
      variant={toastVariantForNotificationLevel[notification.level]}
      isVisible
      onClose={handleOnClose}
    >
      {title && body ? (
        <Text color={COLOR.TEXT_LIGHT} level={2} detail>
          {body}
        </Text>
      ) : null}
    </Notification>
  );
};

export { ToastNotificationItem };
