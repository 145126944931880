import { DomainEvent, MessageName } from "@lookiero/messaging";
import {
  CheckoutBookingBooked,
  CHECKOUT_BOOKING_BOOKED,
} from "../../../../domain/checkoutBooking/model/checkoutBookingBooked";
import { HttpCheckoutBookingsSaveFunction } from "./httpCheckoutBookings";

interface HttpCheckoutBookingsBookFunction extends HttpCheckoutBookingsSaveFunction {}

const isCheckoutBookingBooked = (event: DomainEvent<MessageName>): event is CheckoutBookingBooked =>
  event.name === CHECKOUT_BOOKING_BOOKED;

const httpCheckoutBookingsBook: HttpCheckoutBookingsBookFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const checkoutBookingBooked = domainEvents.find(isCheckoutBookingBooked);

    if (!checkoutBookingBooked) {
      return;
    }

    const { checkoutItemId } = checkoutBookingBooked;

    await httpPost({
      endpoint: "/book-checkout-booking-for-checkout-item",
      body: {
        checkoutItemId,
        checkoutBookingId: aggregateId,
      },
    });
  };

export { httpCheckoutBookingsBook };
