import { getNumberOfUrlSegments } from "@sentry/utils";
import { RouteMatch, RouteObject } from "react-router-native";
import { Location } from "../types";

interface NormalizedNameAndMatchForLocationFunctionArgs {
  readonly routes?: RouteObject[];
  readonly location: Location;
  readonly branches: RouteMatch[];
}

interface NormalizedNameAndMatchForLocationFunction {
  (args: NormalizedNameAndMatchForLocationFunctionArgs): [string, RouteMatch | null];
}

const normalizedNameAndMatchForLocation: NormalizedNameAndMatchForLocationFunction = ({
  routes,
  location,
  branches,
}) => {
  if (!routes || routes.length === 0) {
    return [location.pathname, null];
  }

  let pathBuilder = "";
  if (branches) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let x = 0; x < branches.length; x++) {
      const branch = branches[x] as RouteMatch;
      const route = branch.route;

      if (route) {
        // Early return if index route
        if (route.index) {
          return [branch.pathname, branch];
        }

        const path = route.path;
        if (path) {
          const newPath = path[0] === "/" || pathBuilder[pathBuilder.length - 1] === "/" ? path : `/${path}`;
          pathBuilder += newPath;
          if (branch.pathname === location.pathname) {
            if (
              getNumberOfUrlSegments(pathBuilder) !== getNumberOfUrlSegments(branch.pathname) &&
              pathBuilder.slice(-2) !== "/*"
            ) {
              return [newPath, branch];
            }

            return [pathBuilder, branch];
          }
        }
      }
    }
  }

  return [location.pathname, null];
};

export { normalizedNameAndMatchForLocation };
