import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CheckoutBookingProjection } from "./checkoutBooking";

const VIEW_CHECKOUT_BOOKING_BY_ID = "view_checkout_booking_by_id";

interface ViewCheckoutBookingByIdPayload {
  readonly checkoutBookingId: string;
}

interface ViewCheckoutBookingById extends Query<typeof VIEW_CHECKOUT_BOOKING_BY_ID>, ViewCheckoutBookingByIdPayload {}

interface ViewCheckoutBookingByIdFunction {
  (payload: ViewCheckoutBookingByIdPayload): ViewCheckoutBookingById;
}

const viewCheckoutBookingById: ViewCheckoutBookingByIdFunction = (payload) => ({
  ...query({ name: VIEW_CHECKOUT_BOOKING_BY_ID }),
  ...payload,
});

type ViewCheckoutBookingByIdResult = CheckoutBookingProjection | null;

interface CheckoutBookingByIdViewArgs extends CancelableQueryViewArgs {
  readonly checkoutBookingId: string;
}

interface CheckoutBookingByIdView {
  (args: CheckoutBookingByIdViewArgs): Promise<ViewCheckoutBookingByIdResult>;
}

interface ViewCheckoutBookingByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CheckoutBookingByIdView;
}

const viewCheckoutBookingByIdHandler: QueryHandlerFunction<
  ViewCheckoutBookingById,
  ViewCheckoutBookingByIdResult,
  ViewCheckoutBookingByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutBookingId }) =>
    view({ checkoutBookingId, signal });

export type { ViewCheckoutBookingById, CheckoutBookingByIdView, ViewCheckoutBookingByIdResult };
export { VIEW_CHECKOUT_BOOKING_BY_ID, viewCheckoutBookingById, viewCheckoutBookingByIdHandler };
