import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import invariant from "tiny-invariant";
import { submitCheckout } from "../../../../domain/checkout/command/submitCheckout";
import { Logger } from "../../../../shared/logging/Logger";
import { useCreateToastNotification } from "../../../../shared/notifications";
import { NotificationLevel } from "../../../../shared/notifications/domain/notification/model/notification";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface SubmitFunction {
  (): Promise<void> | undefined;
}

type UseSubmitCheckout = [submit: SubmitFunction, status: CommandStatus];

interface UseSubmitCheckoutFunctionArgs {
  readonly checkoutId?: string;
  readonly logger: Logger;
}

interface UseSubmitCheckoutFunction {
  (args: UseSubmitCheckoutFunctionArgs): UseSubmitCheckout;
}

const useSubmitCheckout: UseSubmitCheckoutFunction = ({ checkoutId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const submit = useCallback(async () => {
    invariant(checkoutId, "CheckoutId must be defined in order to submit checkout");

    try {
      await commandBus(submitCheckout({ aggregateId: checkoutId }));
    } catch (error) {
      logger.captureException(error as Error);
      createNotification({
        level: NotificationLevel.ERROR,
        bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
      });
    }
  }, [checkoutId, commandBus, createNotification, logger]);

  return [submit, status];
};

export { useSubmitCheckout };
