/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
import { GestureHandlerRootView, ScrollView as RNGHScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import { Footer as DefaultFooter } from "../../../templates/footer/Footer";
import { DefaultHeader } from "../../../templates/header/defaultHeader/DefaultHeader";
import { Layout } from "../Layout";
import { Footer } from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import { style } from "./DummyLayout.style";

const DummyLayout: Layout = ({
  children,
  header = <DefaultHeader />,
  footer = <DefaultFooter />,
  panel,
  style: customStyle,
  scrollEnabled,
  onLayout,
}) => (
  <SafeAreaView style={[style.safeAreaView, customStyle?.safeAreaView]}>
    <GestureHandlerRootView style={{ flex: 1 }}>
      <RNGHScrollView
        contentContainerStyle={[style.scrollView, customStyle?.scrollView]}
        scrollEnabled={scrollEnabled}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        stickyHeaderIndices={[0]}
      >
        <Header>{header}</Header>

        {panel}

        <View style={style.children} onLayout={onLayout}>
          {children}
        </View>
      </RNGHScrollView>
    </GestureHandlerRootView>

    <Footer>{footer}</Footer>
  </SafeAreaView>
);

export { DummyLayout };
