import { domainEvent, DomainEvent } from "@lookiero/messaging";

const CHECKOUT_BOOKING_BLOCKED = "checkout_booking_blocked";

interface CheckoutBookingBlokedPaylcoad {
  readonly aggregateId: string;
}

interface CheckoutBookingBlocked extends DomainEvent<typeof CHECKOUT_BOOKING_BLOCKED>, CheckoutBookingBlokedPaylcoad {}

interface CheckoutBookingBlokedFunctcion {
  (payload: CheckoutBookingBlokedPaylcoad): CheckoutBookingBlocked;
}

const checkoutBookingBlocked: CheckoutBookingBlokedFunctcion = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_BOOKING_BLOCKED });

export type { CheckoutBookingBlocked };
export { CHECKOUT_BOOKING_BLOCKED, checkoutBookingBlocked };
