import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CheckoutItemProjection } from "./checkoutItem";

const VIEW_CHECKOUT_ITEM_BY_ID = "view_checkout_item_by_id";

interface ViewCheckoutItemByIdPayload {
  readonly checkoutItemId: string;
}

interface ViewCheckoutItemById extends Query<typeof VIEW_CHECKOUT_ITEM_BY_ID>, ViewCheckoutItemByIdPayload {}

interface ViewCheckoutItemByIdFunction {
  (payload: ViewCheckoutItemByIdPayload): ViewCheckoutItemById;
}

const viewCheckoutItemById: ViewCheckoutItemByIdFunction = (payload) => ({
  ...query({ name: VIEW_CHECKOUT_ITEM_BY_ID }),
  ...payload,
});

type ViewCheckoutItemByIdResult = CheckoutItemProjection | null;

interface CheckoutItemByIdViewArgs extends CancelableQueryViewArgs {
  readonly checkoutItemId: string;
}

interface CheckoutItemByIdView {
  (args: CheckoutItemByIdViewArgs): Promise<ViewCheckoutItemByIdResult>;
}

interface ViewCheckoutItemByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CheckoutItemByIdView;
}

const viewCheckoutItemByIdHandler: QueryHandlerFunction<
  ViewCheckoutItemById,
  ViewCheckoutItemByIdResult,
  ViewCheckoutItemByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutItemId }) =>
    view({ checkoutItemId, signal });

export type { ViewCheckoutItemById, CheckoutItemByIdView, ViewCheckoutItemByIdResult };
export { VIEW_CHECKOUT_ITEM_BY_ID, viewCheckoutItemById, viewCheckoutItemByIdHandler };
