import { BrowserClient, Hub, makeFetchTransport, defaultIntegrations } from "@sentry/browser";
import { FetchImpl } from "@sentry/browser/types/transports/utils";
import { ErrorBoundary } from "@sentry/react";
import { ReactNativeTransportOptions } from "@sentry/react-native/dist/js/options";
import { BrowserTracing } from "@sentry/tracing";
import { Transport } from "@sentry/types";
import { useEffect } from "react";
import { useLocation, useNavigationType, matchRoutes } from "react-router-native";
import {
  reactRouterV6Instrumentation,
  wrapUseRoutes,
} from "./reactRouterV6Instrumentation/reactRouterV6Instrumentation";
import { Integrations, WrapComponent } from "./types";

const transport: (options: ReactNativeTransportOptions, nativeFetch?: FetchImpl) => Transport = (
  options,
  nativeFetch,
) => makeFetchTransport(options, nativeFetch);

/**
 * BrowserTracing must be initialized before the BrowserClient
 * so we instantiate it before the integrations function is called
 */
const browserTracing = new BrowserTracing({
  routingInstrumentation: reactRouterV6Instrumentation({ useEffect, useLocation, useNavigationType, matchRoutes }),
});

const integrations: Integrations = () => [...defaultIntegrations, browserTracing];

const wrapComponent: WrapComponent = (Component) => Component;

export { BrowserClient as Client, Hub, ErrorBoundary, transport, integrations, wrapComponent, wrapUseRoutes };
