import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isCheckoutFeedbackGiven } from "../../../../domain/checkoutFeedback/model/checkoutFeedbackGiven";
import {
  IsCheckoutAccessibleByCustomerIdProjection,
  viewIsCheckoutAccessibleByCustomerId,
} from "../../../../projection/checkout/viewIsCheckoutAccessibleByCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewIsCheckoutAccessibleByCustomerIdFunctionArgs {
  readonly customerId: string | undefined;
}

interface UseViewIsCheckoutAccessibleByCustomerIdFunction {
  (
    args: UseViewIsCheckoutAccessibleByCustomerIdFunctionArgs,
  ): UseQueryFunctionResult<IsCheckoutAccessibleByCustomerIdProjection>;
}

const useViewIsCheckoutAccessibleByCustomerId: UseViewIsCheckoutAccessibleByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: viewIsCheckoutAccessibleByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: isCheckoutFeedbackGiven,
    options: {
      refetchOnMount: "always",
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  });

export { useViewIsCheckoutAccessibleByCustomerId };
