import React, { FC } from "react";
import { View } from "react-native";
import { ModalNotificationProjection } from "../../../../projection/notification/notification";
import { style } from "../toastNotifications/ToastNotifications.style";
import { ModalNotificationItem } from "./ModalNotificationItem";

interface ModalNotificationsProps {
  readonly notifications?: ModalNotificationProjection[];
  readonly onRemove: (notificationId: string) => void;
}

const ModalNotifications: FC<ModalNotificationsProps> = ({ notifications = [], onRemove }) => (
  <View pointerEvents="box-none" style={style.notifications} testID="modal-notifications">
    {notifications.map((notification) => (
      <ModalNotificationItem
        key={notification.id}
        notification={notification}
        testID={notification.id}
        visible
        onRemove={onRemove}
      />
    ))}
  </View>
);

export { ModalNotifications };
