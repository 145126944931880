import React, { ComponentType, useEffect } from "react";
import { useRoutes as reactRouterUseRoutes } from "react-router-native";
import { Logger } from "./Logger";
import { wrapUseRoutes, wrapComponent, ErrorBoundary } from "./SentryDependencies";
import { LoggerProvider } from "./useLogger";

interface SentryLoggerHOCFunctionArgs {
  readonly logger: Logger;
}

interface SentryLoggerHOCProps {
  readonly customer: { readonly customerId: string } | undefined;
}

interface SentryLoggerHOCFunction {
  (
    args: SentryLoggerHOCFunctionArgs,
  ): <P extends SentryLoggerHOCProps>(Component: ComponentType<P>) => ComponentType<P & JSX.IntrinsicAttributes>;
}

const sentryLoggerHOC: SentryLoggerHOCFunction = ({ logger }) => {
  logger.init();

  const useRoutes = wrapUseRoutes(reactRouterUseRoutes);

  return <P extends SentryLoggerHOCProps>(Component: ComponentType<P>) => {
    const WrappedComponent = wrapComponent(Component);

    const SentryLogger = (props: P & JSX.IntrinsicAttributes) => {
      const { customer } = props;

      useEffect(() => {
        logger.onMount(customer?.customerId);

        return logger.onUnmount;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <ErrorBoundary>
          <LoggerProvider logger={logger}>
            <WrappedComponent {...props} useRoutes={useRoutes} />
          </LoggerProvider>
        </ErrorBoundary>
      );
    };

    return SentryLogger;
  };
};

export { sentryLoggerHOC };
