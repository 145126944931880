import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CheckoutQuestionProjection } from "./checkoutQuestion";

const LIST_CHECKOUT_QUESTIONS_BY_CHECKOUT_ID = "list_checkout_questions_by_checkout_id";

interface ListCheckoutQuestionsByCheckoutIdPayload {
  readonly checkoutId: string;
}

interface ListCheckoutQuestionsByCheckoutId
  extends Query<typeof LIST_CHECKOUT_QUESTIONS_BY_CHECKOUT_ID>,
    ListCheckoutQuestionsByCheckoutIdPayload {}

interface ListCheckoutQuestionsByCheckoutIdFunction {
  (payload: ListCheckoutQuestionsByCheckoutIdPayload): ListCheckoutQuestionsByCheckoutId;
}

const listCheckoutQuestionsByCheckoutId: ListCheckoutQuestionsByCheckoutIdFunction = (payload) => ({
  ...query({ name: LIST_CHECKOUT_QUESTIONS_BY_CHECKOUT_ID }),
  ...payload,
});

type ListCheckoutQuestionsByCheckoutIdResult = CheckoutQuestionProjection[] | null;

interface CheckoutQuestionsByCheckoutIdViewArgs extends CancelableQueryViewArgs {
  readonly checkoutId: string;
}

interface CheckoutQuestionsByCheckoutIdView {
  (args: CheckoutQuestionsByCheckoutIdViewArgs): Promise<ListCheckoutQuestionsByCheckoutIdResult>;
}

interface ListCheckoutQuestionsByCheckoutIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CheckoutQuestionsByCheckoutIdView;
}

const listCheckoutQuestionsByCheckoutIdHandler: QueryHandlerFunction<
  ListCheckoutQuestionsByCheckoutId,
  ListCheckoutQuestionsByCheckoutIdResult,
  ListCheckoutQuestionsByCheckoutIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutId }) =>
    view({ checkoutId, signal });

export type {
  ListCheckoutQuestionsByCheckoutId,
  CheckoutQuestionsByCheckoutIdView,
  ListCheckoutQuestionsByCheckoutIdResult,
};
export {
  LIST_CHECKOUT_QUESTIONS_BY_CHECKOUT_ID,
  listCheckoutQuestionsByCheckoutId,
  listCheckoutQuestionsByCheckoutIdHandler,
};
