import { query, Query, QueryHandlerFunction, QueryHandlerFunctionArgs } from "@lookiero/messaging";
import { viewFirstAvailableCheckoutByCustomerId } from "./viewFirstAvailableCheckoutByCustomerId";
import { viewIsCheckoutEnabledByCustomerId } from "./viewIsCheckoutEnabledByCustomerId";

type IsCheckoutAccessibleByCustomerIdProjection = boolean;

const VIEW_IS_CHECKOUT_ACCESSIBLE_BY_CUSTOMER_ID = "view_is_checkout_accessible_by_customer_id";

interface ViewIsCheckoutAccessibleByCustomerIdPayload {
  readonly customerId: string | undefined;
}

interface ViewIsCheckoutAccessibleByCustomerId
  extends Query<typeof VIEW_IS_CHECKOUT_ACCESSIBLE_BY_CUSTOMER_ID>,
    ViewIsCheckoutAccessibleByCustomerIdPayload {}

interface ViewIsCheckoutAccessibleByCustomerIdFunction {
  (payload: ViewIsCheckoutAccessibleByCustomerIdPayload): ViewIsCheckoutAccessibleByCustomerId;
}

const viewIsCheckoutAccessibleByCustomerId: ViewIsCheckoutAccessibleByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_IS_CHECKOUT_ACCESSIBLE_BY_CUSTOMER_ID }),
  ...payload,
});

interface ViewIsCheckoutAccessibleByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {}

const isNonEmptyCustomerId = (customerId: string | undefined): customerId is string => Boolean(customerId);

const viewIsCheckoutAccessibleByCustomerIdHandler: QueryHandlerFunction<
  ViewIsCheckoutAccessibleByCustomerId,
  IsCheckoutAccessibleByCustomerIdProjection,
  ViewIsCheckoutAccessibleByCustomerIdHandlerFunctionArgs
> =
  ({ queryBus }) =>
  async ({ customerId }) =>
    isNonEmptyCustomerId(customerId) &&
    Boolean(await queryBus(viewFirstAvailableCheckoutByCustomerId({ customerId }))) &&
    Boolean(await queryBus(viewIsCheckoutEnabledByCustomerId({ customerId })));

export type { IsCheckoutAccessibleByCustomerIdProjection };
export {
  VIEW_IS_CHECKOUT_ACCESSIBLE_BY_CUSTOMER_ID,
  viewIsCheckoutAccessibleByCustomerId,
  viewIsCheckoutAccessibleByCustomerIdHandler,
};
