import { useCallback } from "react";
import { Currency } from "../../../domain/checkoutItem/model/currency";
import { PROJECT, TrackingPage } from "../../../infrastructure/tracking/tracking";
import { Country } from "../../../projection/shared/country";
import { Subscription } from "../../../projection/shared/subscription";
import { CheckoutTrackingEvent, TrackingEvent, TrackingEventCategory } from "../tracking";
import { useEmitUserEvent } from "./useEmitUserEvent";

interface TrackCheckoutFunctionArgs {
  readonly userId: string;
  readonly totalReplacedFor: number;
  readonly isFirstOrder: boolean;
  readonly totalCheckoutItemsKept: number;
  readonly currencyCode: Currency;
  readonly subscription: Subscription;
  readonly coupon: string | null;
  readonly orderNumber: number;
  readonly pendingToPay: number;
}
interface TrackCheckoutFunction {
  (args: TrackCheckoutFunctionArgs): void;
}

interface UseTrackCheckoutFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly checkoutId: string | undefined;
}
interface UseTrackCheckoutFunction {
  (args: UseTrackCheckoutFunctionArgs): TrackCheckoutFunction;
}

const useTrackCheckout: UseTrackCheckoutFunction = ({ page, country, checkoutId }) => {
  const emitUserEvent = useEmitUserEvent<CheckoutTrackingEvent>();

  const trackCheckout: TrackCheckoutFunction = useCallback(
    ({
      coupon,
      currencyCode,
      isFirstOrder,
      orderNumber,
      pendingToPay,
      subscription,
      totalCheckoutItemsKept,
      totalReplacedFor,
      userId,
    }) => {
      if (!checkoutId) {
        return;
      }

      const checkoutTrackingEvent: CheckoutTrackingEvent = {
        event: TrackingEvent.CHECKOUT,
        eventCategory: TrackingEventCategory.ECOMMERCE,
        section: `${PROJECT}_${page}`,
        store: country,
        checkoutId,
        userId,
        sizeChanges: totalReplacedFor,
        isFirstOrder,
        ecommerce: {
          checkout: {
            actionField: {
              items: totalCheckoutItemsKept,
              currencyCode,
              subscription,
              coupon,
              orderId: orderNumber,
              value: pendingToPay,
            },
          },
        },
      };

      emitUserEvent(checkoutTrackingEvent);
    },
    [checkoutId, country, emitUserEvent, page],
  );

  return trackCheckout;
};

export { useTrackCheckout };
