import { PricingProjection, ServiceProjection } from "../../../projection/pricing/pricing";
import { PriceProjection } from "../../../projection/shared/price";

interface PricingDto {
  readonly balanceDiscount: PriceProjection;
  readonly discount: PriceProjection;
  readonly discountPercentage: number;
  readonly orderTotal: PriceProjection;
  readonly pendingToPay: PriceProjection;
  readonly service: ServiceProjection;
  readonly subtotal: PriceProjection;
  readonly paidWithPromocode?: boolean;
}

interface ToPricingProjectionFunction {
  (pricingDto: PricingDto): PricingProjection;
}

const toPricingProjection: ToPricingProjectionFunction = (pricingDto) => ({
  ...pricingDto,
  discount: {
    ...pricingDto.discount,
    amount: -Math.abs(pricingDto.discount.amount),
  },
  balanceDiscount: {
    ...pricingDto.balanceDiscount,
    amount: -Math.abs(pricingDto.balanceDiscount.amount),
  },
});

export type { PricingDto };
export { toPricingProjection };
