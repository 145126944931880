enum Locale {
  ES = "es",
  FR = "fr",
  EN = "en",
  IT = "it",
  PT = "pt",
  DE = "de",
  AT = "at",
  NL = "nl",
  SE = "sv",
}

export { Locale };
