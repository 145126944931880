import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_ITEM_RETURNED = "checkout_item_returned";

interface CheckoutItemReturnedPayload {
  readonly aggregateId: string;
}

interface CheckoutItemReturned extends DomainEvent<typeof CHECKOUT_ITEM_RETURNED>, CheckoutItemReturnedPayload {}

interface CheckoutItemReturnedFunction {
  (payload: CheckoutItemReturnedPayload): CheckoutItemReturned;
}

const checkoutItemReturned: CheckoutItemReturnedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_ITEM_RETURNED });

const isCheckoutItemReturned = (event: DomainEvent<MessageName>): event is CheckoutItemReturned =>
  event.name === CHECKOUT_ITEM_RETURNED;

export type { CheckoutItemReturned };
export { CHECKOUT_ITEM_RETURNED, checkoutItemReturned, isCheckoutItemReturned };
