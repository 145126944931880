import { DomainEvent, MessageName } from "@lookiero/messaging";
import { CheckoutItemKept, CHECKOUT_ITEM_KEPT } from "../../../../domain/checkoutItem/model/checkoutItemKept";
import { HttpCheckoutItemsSaveFunction } from "./httpCheckoutItems";

interface HttpCheckoutItemsKeepFunction extends HttpCheckoutItemsSaveFunction {}

const isCheckoutItemKept = (event: DomainEvent<MessageName>): event is CheckoutItemKept =>
  event.name === CHECKOUT_ITEM_KEPT;

const httpCheckoutItemsKeep: HttpCheckoutItemsKeepFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const checkoutItemKept = domainEvents.find(isCheckoutItemKept);

    if (!checkoutItemKept) {
      return;
    }

    await httpPost({
      endpoint: "/keep-checkout-item",
      body: { checkoutItemId: aggregateId },
    });
  };

export { httpCheckoutItemsKeep };
