import { ProcessManagerFunction } from "@lookiero/messaging";
import { I18nMessages } from "../../../infrastructure/ui/i18n/i18n";
import { createModalNotification } from "../../../shared/notifications/domain/notification/command/createModalNotification";
import { CheckoutSubmitted } from "../model/checkoutSubmitted";

const CHECKOUT_SUCCESS_NOTIFICATION_ID = "fa25dcdd-9f5d-4760-9e6f-27174205b1fa";

interface CreateModalNotificationWhenCheckoutSubmittedFunction extends ProcessManagerFunction<CheckoutSubmitted> {}

const createModalNotificationWhenCheckoutSubmitted: CreateModalNotificationWhenCheckoutSubmittedFunction =
  ({ commandBus }) =>
  async () => {
    await commandBus(
      createModalNotification({
        aggregateId: CHECKOUT_SUCCESS_NOTIFICATION_ID,
        titleI18nKey: I18nMessages.CHECKOUT_SUCCESS_MODAL_TITLE,
        bodyI18nKey: I18nMessages.CHECKOUT_SUCCESS_MODAL_DESCRIPTION,
        acceptI18nKey: I18nMessages.CHECKOUT_SUCCESS_MODAL_BUTTON,
      }),
    );
  };

export { createModalNotificationWhenCheckoutSubmitted, CHECKOUT_SUCCESS_NOTIFICATION_ID };
