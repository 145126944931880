const PROJECT = "checkout";

enum TrackingPage {
  ITEM = "item",
  CHECKOUT = "checkout",
  SUMMARY = "summary",
  FEEDBACK = "feedback",
  RETURN = "return",
}

export { PROJECT, TrackingPage };
