const EXCLUDED_VARIATION_NAME = "Excluded";

interface ExcludedVariation {
  readonly id: null;
  readonly name: typeof EXCLUDED_VARIATION_NAME;
}

interface Variation {
  readonly id: number;
  readonly name: string;
}

const isExcludedVariation = (variation: Variation | ExcludedVariation): variation is ExcludedVariation =>
  variation.name === EXCLUDED_VARIATION_NAME && variation.id === null;

interface AssignedVariantByExperimentIdFunctionArgs {
  readonly experimentId: string;
}

interface AssignedVariantByExperimentIdFunction {
  (args: AssignedVariantByExperimentIdFunctionArgs): Variation | ExcludedVariation | undefined;
}

interface TrackConversionFunctionArgs {
  readonly goalId: number;
}

interface TrackConversionFunction {
  (args: TrackConversionFunctionArgs): void;
}

interface Kameleoon {
  readonly assignedVariantByExperimentId: AssignedVariantByExperimentIdFunction;
  readonly trackConversion: TrackConversionFunction;
}

interface Experiment {
  readonly id: string;
  readonly associatedVariation: Variation | undefined;
}

interface Experiments {
  readonly getById: (experimentId: string) => Experiment | undefined;
}

interface Core {
  readonly load: () => void;
}

interface Goals {
  readonly processConversion: (goalNameOrID: string | number, revenue?: number) => void;
}

interface API {
  readonly Core: Core;
  readonly Experiments: Experiments;
  readonly Goals: Goals;
}

interface KameleoonAPI {
  readonly API: API;
}

export { isExcludedVariation };

export type {
  Kameleoon,
  Variation,
  KameleoonAPI,
  TrackConversionFunction,
  AssignedVariantByExperimentIdFunction,
  ExcludedVariation,
};
