import React, { createContext, FC, ReactNode, useContext } from "react";
import invariant from "tiny-invariant";
import { KameleoonEnvironment } from "../../testing/kameleoonEnvironment";

interface StaticInfo {
  readonly kameleoon: KameleoonEnvironment;
}

const StaticInfoContext = createContext<StaticInfo>(null as unknown as StaticInfo);

interface StaticInfoProviderProps {
  readonly children: ReactNode;
  readonly kameleoon: KameleoonEnvironment;
}

const StaticInfoProvider: FC<StaticInfoProviderProps> = ({ children, kameleoon }) => (
  <StaticInfoContext.Provider value={{ kameleoon }}>{children}</StaticInfoContext.Provider>
);

const useStaticInfo = () => {
  const staticInfo = useContext(StaticInfoContext);

  invariant(
    staticInfo,
    "Your are trying to use the useStaticInfo hook without wrapping your app with the <StaticInfoProvider>.",
  );

  return staticInfo;
};

export { useStaticInfo, StaticInfoProvider };
