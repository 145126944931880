import { DomainEvent, MessageName } from "@lookiero/messaging";
import {
  CheckoutItemReturned,
  CHECKOUT_ITEM_RETURNED,
} from "../../../../domain/checkoutItem/model/checkoutItemReturned";
import { HttpCheckoutItemsSaveFunction } from "./httpCheckoutItems";

interface HttpCheckoutItemsReturnFunction extends HttpCheckoutItemsSaveFunction {}

const isCheckoutItemReturned = (event: DomainEvent<MessageName>): event is CheckoutItemReturned =>
  event.name === CHECKOUT_ITEM_RETURNED;

const httpCheckoutItemsReturn: HttpCheckoutItemsReturnFunction =
  ({ httpPost }) =>
  async ({ aggregateId, feedbacks, domainEvents }) => {
    const checkoutItemReturned = domainEvents.find(isCheckoutItemReturned);

    if (!checkoutItemReturned) {
      return;
    }

    await httpPost({
      endpoint: "/return-checkout-item",
      body: { checkoutItemId: aggregateId, feedbacks },
    });
  };

export { httpCheckoutItemsReturn };
