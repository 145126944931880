import { defaultStackParser } from "@sentry/browser";
import { makeMain } from "@sentry/core";
import { Platform } from "react-native";
import { Logger } from "./Logger";
import { Client, Hub, integrations, transport } from "./SentryDependencies";

// This configuration is obtained from
// https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
const IGNORED_ERRORS = [
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  "Script error.",
  // Avast extension error
  "_avast_submit",
];
const DENIED_URLS = [
  // Google Adsense
  /pagead\/js/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

interface SentryEnvironment {
  readonly publicKey: string;
  readonly environment: `${typeof Platform.OS}-${"DEV" | "PROD" | "EXPO"}`;
  readonly release: string;
  readonly project: string;
}

interface SentryLoggerFunctionArgs {
  (): SentryEnvironment;
}

interface SentryLoggerFunction {
  (args: SentryLoggerFunctionArgs): Logger;
}

const sentryLogger: SentryLoggerFunction = (environment) => {
  let globalHub: Hub | null;
  let currentHub: Hub | null;

  const createHub = () => {
    const { environment: clientEnvironment, release, publicKey, project } = environment();

    const client = new Client({
      environment: clientEnvironment,
      release,
      dsn: `https://${publicKey}@o179049.ingest.sentry.io/${project}`,
      integrations: integrations(),
      tracesSampleRate: 0.5,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enableNative: Platform.OS !== "web",
      autoInitializeNativeSdk: Platform.OS !== "web",
      stackParser: defaultStackParser,
      transport,
      ignoreErrors: IGNORED_ERRORS,
      denyUrls: DENIED_URLS,
    });

    return new Hub(client);
  };

  const init = () => {
    /**
     * BrowserClient must be initialized before wrapUseRoutes is used.
     *
     * ReactNativeClient cannot be initialized until actual component is rendered.
     * Its initialization will trigger the native sdk setup with the provided settings,
     * but by the time an event will be sent to Sentry, the host project (UAF)
     * will have overridden its configuration (dsn, etc) after initializing it
     * so we have to initialize it after the component mount.
     */
    if (Platform.OS === "web") {
      currentHub = createHub();
    }
  };

  const onMount = (customerId: string | undefined) => {
    // currentHub won't be initialized in native until this time
    currentHub = currentHub ?? createHub();
    currentHub.setUser({ id: customerId });
    currentHub.startSession();

    if (!globalHub) {
      globalHub = makeMain(currentHub);
    }
  };

  const onUnmount = () => {
    if (currentHub) {
      currentHub.endSession();
      currentHub.setUser(null);
      currentHub = null;
    }

    if (globalHub) {
      makeMain(globalHub);
      globalHub = null;
    }
  };

  const captureException = (error: Error) => {
    currentHub?.captureException(error);
  };

  return {
    init,
    onMount,
    onUnmount,
    captureException,
  };
};

export type { SentryEnvironment };
export { sentryLogger };
