import {
  FiveItemsDiscountByCustomerIdProjection,
  FiveItemsDiscountByCustomerIdView,
} from "../../../projection/checkout/viewFiveItemsDiscountByCustomerId";
import { HttpGetFunction } from "../../delivery/http/httpClient";

interface HttpFiveItemsDiscountByCustomerIdView extends FiveItemsDiscountByCustomerIdView {}

interface HttpFiveItemsDiscountByCustomerIdViewFunctionArgs {
  readonly httpGet: HttpGetFunction;
}

interface HttpFiveItemsDiscountByCustomerIdViewFunction {
  (args: HttpFiveItemsDiscountByCustomerIdViewFunctionArgs): HttpFiveItemsDiscountByCustomerIdView;
}

const httpFiveItemsDiscountByCustomerIdView: HttpFiveItemsDiscountByCustomerIdViewFunction =
  ({ httpGet }) =>
  async ({ customerId, signal }) =>
    await httpGet<FiveItemsDiscountByCustomerIdProjection>({
      endpoint: `/view-five-items-discount-by-customer-id/${customerId}`,
      signal,
      result: {
        error: 0,
        success: (response) => response,
      },
    });

export { httpFiveItemsDiscountByCustomerIdView };
