import { ProcessManagerFunction } from "@lookiero/messaging";
import { I18nMessages } from "../../../infrastructure/ui/i18n/i18n";
import { createToastNotification } from "../../../shared/notifications/domain/notification/command/createToastNotification";
import { NotificationLevel } from "../../../shared/notifications/domain/notification/model/notification";
import { CheckoutBookingExpired } from "../model/checkoutBookingExpired";

const CHECKOUT_BOOKING_EXPIRED_NOTIFICATION_ID = "d423934e-2499-46ab-8cfb-39e1e398237e";

interface CreateToastNotificationWhenCheckoutBookingExpiredFunction
  extends ProcessManagerFunction<CheckoutBookingExpired> {}

const createToastNotificationWhenCheckoutBookingExpired: CreateToastNotificationWhenCheckoutBookingExpiredFunction =
  ({ commandBus }) =>
  async () => {
    await commandBus(
      createToastNotification({
        aggregateId: CHECKOUT_BOOKING_EXPIRED_NOTIFICATION_ID,
        level: NotificationLevel.ERROR,
        titleI18nKey: undefined,
        bodyI18nKey: I18nMessages.CHECKOUT_TOAST_BOOKING_EXPIRED,
      }),
    );
  };

export { createToastNotificationWhenCheckoutBookingExpired, CHECKOUT_BOOKING_EXPIRED_NOTIFICATION_ID };
