import { StyleSheet, ViewStyle } from "react-native";
import { ColumnSize } from "./Column";

const stylesForColumSize: Record<ColumnSize, ViewStyle> = {
  ["1/4"]: {
    maxWidth: `${100 * (1 / 4)}%`,
  },
  ["1/3"]: {
    maxWidth: `${100 * (1 / 3)}%`,
  },
  ["1/2"]: {
    maxWidth: `${100 * (1 / 2)}%`,
  },
  ["2/3"]: {
    maxWidth: `${100 * (2 / 3)}%`,
  },
};

const style = StyleSheet.create({
  column: {
    flex: 1,
    width: "100%",
  },
  ...stylesForColumSize,
});

export { style };
