import { Button, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import React, { FC, useEffect, useRef } from "react";
import { useCallback } from "react";
import { Modal } from "../../../../../ui/components/layouts/modal/Modal";
import { ModalNotificationProjection } from "../../../../projection/notification/notification";
import { style } from "./ModalNotificationItem.style";

interface ModalNotificationItemProps {
  readonly visible: boolean;
  readonly notification: ModalNotificationProjection;
  readonly onRemove: (notificationId: string) => void;
  readonly testID?: string;
}

const ModalNotificationItem: FC<ModalNotificationItemProps> = ({
  visible,
  notification,
  onRemove,
  testID = "modal-notification-item",
}) => {
  const titleText = useI18nMessage({ id: notification.titleI18nKey });
  const bodyText = useI18nMessage({ id: notification.bodyI18nKey });
  const acceptText = useI18nMessage({ id: notification.acceptI18nKey });

  const modalNotificationClosedRef = useRef(false);
  const handleOnClose = useCallback(() => {
    onRemove(notification.id);
    modalNotificationClosedRef.current = true;
  }, [notification.id, onRemove]);

  /**
   * Remove modal notification when this view is unmounted
   */
  useEffect(
    () => () => {
      if (!modalNotificationClosedRef.current) {
        onRemove(notification.id);
      }
    },
    [notification.id, onRemove],
  );

  return (
    <Modal testID={testID} visible={visible} onClose={handleOnClose}>
      <Text level={3} style={style.title} heading>
        {titleText}
      </Text>
      <Text level={3} style={style.description}>
        {bodyText}
      </Text>
      <Button testID="modal-notification-button" onPress={handleOnClose}>
        {acceptText}
      </Button>
    </Modal>
  );
};

export { ModalNotificationItem };
