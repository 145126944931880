import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_STARTED = "checkout_started";

interface CheckoutStartedPayload {
  readonly aggregateId: string;
}

interface CheckoutStarted extends DomainEvent<typeof CHECKOUT_STARTED>, CheckoutStartedPayload {}

interface CheckoutStartedFunction {
  (payload: CheckoutStartedPayload): CheckoutStarted;
}

const checkoutStarted: CheckoutStartedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_STARTED });

const isCheckoutStarted = (event: DomainEvent<MessageName>): event is CheckoutStarted =>
  event.name === CHECKOUT_STARTED;

export type { CheckoutStarted };
export { CHECKOUT_STARTED, checkoutStarted, isCheckoutStarted };
