enum Country {
  ES = "ES",
  FR = "FR",
  GB = "GB",
  BE = "BE",
  LU = "LU",
  IT = "IT",
  PT = "PT",
  DE = "DE",
  AT = "AT",
  NL = "NL",
  SE = "SE",
}

export { Country };
