import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isCheckoutItemKept } from "../../../../domain/checkoutItem/model/checkoutItemKept";
import { isCheckoutItemReplaced } from "../../../../domain/checkoutItem/model/checkoutItemReplaced";
import { isCheckoutItemReset } from "../../../../domain/checkoutItem/model/checkoutItemReset";
import { isCheckoutItemReturned } from "../../../../domain/checkoutItem/model/checkoutItemReturned";
import { PricingProjection } from "../../../../projection/pricing/pricing";
import { viewPricingByCheckoutId } from "../../../../projection/pricing/viewPricingByCheckoutId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isCheckoutItemKept(event) ||
  isCheckoutItemReplaced(event) ||
  isCheckoutItemReturned(event) ||
  isCheckoutItemReset(event);

interface QueryOptions {
  readonly refetchOnMount?: boolean | "always";
}

const DEFAULT_QUERY_OPTIONS: QueryOptions = {
  refetchOnMount: "always",
};

interface UseViewPricingByCheckoutIdFunctionArgs {
  readonly checkoutId: string;
  readonly queryOptions?: QueryOptions;
}

interface UseViewPricingByCheckoutIdFunction {
  (args: UseViewPricingByCheckoutIdFunctionArgs): UseQueryFunctionResult<PricingProjection>;
}

const useViewPricingByCheckoutId: UseViewPricingByCheckoutIdFunction = ({
  checkoutId,
  queryOptions = DEFAULT_QUERY_OPTIONS,
}) =>
  useQuery({
    query: viewPricingByCheckoutId({ checkoutId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      refetchOnMount: queryOptions.refetchOnMount,
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

export { useViewPricingByCheckoutId };
