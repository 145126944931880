import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_SUBMITTED = "checkout_submitted";

interface CheckoutSubmittedPayload {
  readonly aggregateId: string;
}

interface CheckoutSubmitted extends DomainEvent<typeof CHECKOUT_SUBMITTED>, CheckoutSubmittedPayload {}

interface CheckoutSubmittedFunction {
  (payload: CheckoutSubmittedPayload): CheckoutSubmitted;
}

const checkoutSubmitted: CheckoutSubmittedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_SUBMITTED });

const isCheckoutSubmitted = (event: DomainEvent<MessageName>): event is CheckoutSubmitted =>
  event.name === CHECKOUT_SUBMITTED;

export type { CheckoutSubmitted };
export { CHECKOUT_SUBMITTED, checkoutSubmitted, isCheckoutSubmitted };
