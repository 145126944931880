import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_ITEM_RESET = "checkout_item_reset";

interface CheckoutItemResetPayload {
  readonly aggregateId: string;
}

interface CheckoutItemReset extends DomainEvent<typeof CHECKOUT_ITEM_RESET>, CheckoutItemResetPayload {}

interface CheckoutItemResetFunction {
  (payload: CheckoutItemResetPayload): CheckoutItemReset;
}

const checkoutItemReset: CheckoutItemResetFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_ITEM_RESET });

const isCheckoutItemReset = (event: DomainEvent<MessageName>): event is CheckoutItemReset =>
  event.name === CHECKOUT_ITEM_RESET;

export type { CheckoutItemReset };
export { CHECKOUT_ITEM_RESET, checkoutItemReset, isCheckoutItemReset };
