import { Theme } from "@lookiero/aurora";
import { StyleSheet } from "react-native";

const { space1, space6, colorTextMedium } = Theme.get();

const style = StyleSheet.create({
  description: {
    color: colorTextMedium,
    marginBottom: space6,
    width: "100%",
  },
  title: {
    marginBottom: space1,
    width: "100%",
  },
});

export { style };
