import { PortalProvider } from "@gorhom/portal";
import { PortalProvider as AuroraPortalProvider, Theme } from "@lookiero/aurora";
import React, { FC } from "react";
import { StatusBar } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Notifications } from "../../../shared/notifications/infrastructure/ui/views/Notifications";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";

const { colorBgBase } = Theme.get();

interface AppProps {
  readonly children: JSX.Element;
}

const App: FC<AppProps> = ({ children }) => (
  <SafeAreaProvider>
    <PortalProvider>
      <StatusBar backgroundColor={colorBgBase} barStyle="dark-content" translucent />
      <Notifications contextId={MESSAGING_CONTEXT_ID} />
      {/*
        We are using the Aurora's PortalProvider at this level for notifications to work properly.

        PaymentInstrumentSelect uses Aurora's Portal, and if we rely on UAF's Portal (injected by <Aurora>)
        notifications would be displayed in a layer below Portal's one (not visible).
      */}
      <AuroraPortalProvider>{children}</AuroraPortalProvider>
    </PortalProvider>
  </SafeAreaProvider>
);

export { App };
