import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_FEEDBACK_GIVEN = "checkout_feedback_given";

interface CheckoutFeedbackGivenPayload {
  readonly aggregateId: string;
  readonly checkoutId: string;
}

interface CheckoutFeedbackGiven extends DomainEvent<typeof CHECKOUT_FEEDBACK_GIVEN>, CheckoutFeedbackGivenPayload {}

interface CheckoutFeedbackGivenFunction {
  (payload: CheckoutFeedbackGivenPayload): CheckoutFeedbackGiven;
}

const checkoutFeedbackGiven: CheckoutFeedbackGivenFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: CHECKOUT_FEEDBACK_GIVEN }),
  ...payload,
});

const isCheckoutFeedbackGiven = (event: DomainEvent<MessageName>): event is CheckoutFeedbackGiven =>
  event.name === CHECKOUT_FEEDBACK_GIVEN;

export type { CheckoutFeedbackGiven };
export { CHECKOUT_FEEDBACK_GIVEN, checkoutFeedbackGiven, isCheckoutFeedbackGiven };
