import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { BookedProductsVariantsProjection } from "./bookedProductsVariants";

const VIEW_BOOKED_PRODUCT_VARIANTS_FOR_CHECKOUT_ITEM = "view_booked_product_variants_for_checkout_item";

interface ViewBookedProductsVariantsForCheckoutItemPayload {
  readonly checkoutItemId: string;
}

interface ViewBookedProductsVariantsForCheckoutItem
  extends Query<typeof VIEW_BOOKED_PRODUCT_VARIANTS_FOR_CHECKOUT_ITEM>,
    ViewBookedProductsVariantsForCheckoutItemPayload {}

interface ViewBookedProductsVariantsForCheckoutItemFunction {
  (payload: ViewBookedProductsVariantsForCheckoutItemPayload): ViewBookedProductsVariantsForCheckoutItem;
}

const viewBookedProductsVariantsForCheckoutItem: ViewBookedProductsVariantsForCheckoutItemFunction = (payload) => ({
  ...query({ name: VIEW_BOOKED_PRODUCT_VARIANTS_FOR_CHECKOUT_ITEM }),
  ...payload,
});

type ViewBookedProductsVariantsForCheckoutItemResult = BookedProductsVariantsProjection | null;

interface BookedProductsVariantsForCheckoutItemViewArgs extends CancelableQueryViewArgs {
  readonly checkoutItemId: string;
}

interface BookedProductsVariantsForCheckoutItemView {
  (args: BookedProductsVariantsForCheckoutItemViewArgs): Promise<ViewBookedProductsVariantsForCheckoutItemResult>;
}

interface ViewBookedProductsVariantsForCheckoutItemHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: BookedProductsVariantsForCheckoutItemView;
}

const viewBookedProductsVariantsForCheckoutItemHandler: QueryHandlerFunction<
  ViewBookedProductsVariantsForCheckoutItem,
  ViewBookedProductsVariantsForCheckoutItemResult,
  ViewBookedProductsVariantsForCheckoutItemHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutItemId }) =>
    view({ checkoutItemId, signal });

export type {
  ViewBookedProductsVariantsForCheckoutItem,
  BookedProductsVariantsForCheckoutItemView,
  ViewBookedProductsVariantsForCheckoutItemResult,
};
export {
  VIEW_BOOKED_PRODUCT_VARIANTS_FOR_CHECKOUT_ITEM,
  viewBookedProductsVariantsForCheckoutItem,
  viewBookedProductsVariantsForCheckoutItemHandler,
};
