import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import invariant from "tiny-invariant";
import { Checkout } from "../../../../domain/checkout/model/checkout";
import { CheckoutsGetFunction, CheckoutsSaveFunction } from "../../../../domain/checkout/model/checkouts";
import {
  viewCheckoutById,
  ViewCheckoutById,
  ViewCheckoutByIdResult,
} from "../../../../projection/checkout/viewCheckoutById";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpCheckoutsStart } from "./httpCheckoutsStart";
import { httpCheckoutsSubmit } from "./httpCheckoutsSubmit";

interface ToDomainFunction {
  (checkout: ViewCheckoutByIdResult): Checkout | never;
}

const toDomain: ToDomainFunction = (checkout) => {
  invariant(checkout, "No checkout found!");

  return {
    id: checkout.id,
    status: checkout.status,
    customerId: checkout.customerId,
    boxId: checkout.boxId,
    checkoutBookingId: checkout.checkoutBookingId,
    expiresOn: checkout.expiresOn,
    aggregateId: checkout.id,
    domainEvents: [],
  };
};

interface HttpCheckoutsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpCheckoutsGetFunction extends CheckoutsGetFunction<HttpCheckoutsGetFunctionArgs> {}

const getCheckout: HttpCheckoutsGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(await queryBus<ViewCheckoutById, ViewCheckoutByIdResult>(viewCheckoutById({ checkoutId: aggregateId })));

interface HttpCheckoutsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutsSaveFunction extends CheckoutsSaveFunction<HttpCheckoutsSaveFunctionArgs> {}

const saveCheckout: HttpCheckoutsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([
      httpCheckoutsStart({ httpPost })(aggregateRoot),
      httpCheckoutsSubmit({ httpPost })(aggregateRoot),
    ]);
  };

export type { HttpCheckoutsSaveFunction };
export { getCheckout, saveCheckout };
