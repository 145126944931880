import { Theme } from "@lookiero/aurora";
import { Platform, StyleSheet, ViewStyle } from "react-native";
import { ColumnSize } from "../column/Column";

const { borderRadius5, colorBgBase, colorBgOpacity, space6, space8 } = Theme.get();

const modalStylesForColumSize: Record<ColumnSize, ViewStyle> = {
  ["1/4"]: {
    maxWidth: `${100 * (1 / 4)}%`,
  },
  ["1/3"]: {
    maxWidth: `${100 * (1 / 3)}%`,
  },
  ["1/2"]: {
    maxWidth: `${100 * (1 / 2)}%`,
  },
  ["2/3"]: {
    maxWidth: `${100 * (2 / 3)}%`,
  },
};

const style = StyleSheet.create({
  closeButton: {
    padding: 0,
  },
  container: {
    height: "100%",
    overflow: "hidden",
    ...(Platform.select({
      web: { position: "fixed" },
      android: { position: "absolute" },
      ios: { position: "absolute" },
    }) as ViewStyle),
    width: "100%",
    zIndex: 100,
  },
  header: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: space6,
  },
  modal: {
    backgroundColor: colorBgBase,
    padding: space6,
    paddingBottom: 0,
    position: "absolute",
    width: "100%",
    zIndex: 3,
  },
  modalLarge: {
    borderRadius: borderRadius5,
  },
  ...modalStylesForColumSize,
  overlay: {
    backgroundColor: colorBgOpacity,
    ...Platform.select({
      web: { cursor: "pointer" },
    }),
    height: "100%",
    position: "absolute",
    width: "100%",
    zIndex: 1,
  },
  row: {
    alignContent: "center",
    flex: 1,
    justifyContent: "center",
  },
  rowSmall: {
    justifyContent: "flex-end",
  },
  safeArea: {
    flex: 1,
    zIndex: 2,
  },
  stickyFooter: {
    paddingHorizontal: space8,
  },
});

export { style };
