import React, { FC } from "react";
import { View, ViewProps } from "react-native";
import { ScreenSize, useScreenSize } from "../../../hooks/useScreenSize";
import { style } from "./Column.style";

type ColumnSize = "1/4" | "1/3" | "1/2" | "2/3";

interface ColumnProps extends ViewProps {
  readonly size?: Partial<Record<ScreenSize, ColumnSize>>;
}

const Column: FC<ColumnProps> = ({ children, style: customStyle, size = { M: "2/3", L: "1/3" } }) => {
  const screenSize = useScreenSize();
  const columnSizeForScreenSize = size[screenSize];

  return (
    <View style={[style.column, columnSizeForScreenSize && style[columnSizeForScreenSize], customStyle]}>
      {children}
    </View>
  );
};

export type { ColumnSize };
export { Column };
