import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_BOOKING_EXPIRED = "checkout_booking_expired";

interface CheckoutBookingExpiredPayload {
  readonly aggregateId: string;
}

interface CheckoutBookingExpired extends DomainEvent<typeof CHECKOUT_BOOKING_EXPIRED>, CheckoutBookingExpiredPayload {}

interface CheckoutBookingExpiredFunction {
  (payload: CheckoutBookingExpiredPayload): CheckoutBookingExpired;
}

const checkoutBookingExpired: CheckoutBookingExpiredFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_BOOKING_EXPIRED });

const isCheckoutBookingExpired = (event: DomainEvent<MessageName>): event is CheckoutBookingExpired =>
  event.name === CHECKOUT_BOOKING_EXPIRED;

export type { CheckoutBookingExpired };
export { CHECKOUT_BOOKING_EXPIRED, checkoutBookingExpired, isCheckoutBookingExpired };
