import { ProcessManagerFunction } from "@lookiero/messaging";
import { I18nMessages } from "../../../infrastructure/ui/i18n/i18n";
import { createModalNotification } from "../../../shared/notifications/domain/notification/command/createModalNotification";
import { CheckoutItemReplaced } from "../model/checkoutItemReplaced";

const SIZE_CHANGE_NOTIFICATION_ID = "ce89b8b2-e025-4b54-8cdc-52520dc3dd23";

interface CreateModalNotificationWhenCheckoutItemReplacedFunction
  extends ProcessManagerFunction<CheckoutItemReplaced> {}

const createModalNotificationWhenCheckoutItemReplaced: CreateModalNotificationWhenCheckoutItemReplacedFunction =
  ({ commandBus }) =>
  async () => {
    await commandBus(
      createModalNotification({
        aggregateId: SIZE_CHANGE_NOTIFICATION_ID,
        titleI18nKey: I18nMessages.SIZE_CHANGE_MODAL_TITLE,
        bodyI18nKey: I18nMessages.SIZE_CHANGE_MODAL_DESCRIPTION,
        acceptI18nKey: I18nMessages.SIZE_CHANGE_MODAL_BUTTON,
      }),
    );
  };

export { createModalNotificationWhenCheckoutItemReplaced, SIZE_CHANGE_NOTIFICATION_ID };
