import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CheckoutProjection } from "./checkout";

const VIEW_CHECKOUT_BY_ID = "view_checkout_by_id";

interface ViewCheckoutByIdPayload {
  readonly checkoutId: string;
}

interface ViewCheckoutById extends Query<typeof VIEW_CHECKOUT_BY_ID>, ViewCheckoutByIdPayload {}

interface ViewCheckoutByIdFunction {
  (payload: ViewCheckoutByIdPayload): ViewCheckoutById;
}

const viewCheckoutById: ViewCheckoutByIdFunction = (payload) => ({
  ...query({ name: VIEW_CHECKOUT_BY_ID }),
  ...payload,
});

type ViewCheckoutByIdResult = CheckoutProjection | null;

interface CheckoutByIdViewArgs extends CancelableQueryViewArgs {
  readonly checkoutId: string;
}

interface CheckoutByIdView {
  (args: CheckoutByIdViewArgs): Promise<ViewCheckoutByIdResult>;
}

interface ViewCheckoutByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CheckoutByIdView;
}

const viewCheckoutByIdHandler: QueryHandlerFunction<
  ViewCheckoutById,
  ViewCheckoutByIdResult,
  ViewCheckoutByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ checkoutId }) =>
    view({ checkoutId, signal });

export type { ViewCheckoutById, CheckoutByIdView, ViewCheckoutByIdResult };
export { VIEW_CHECKOUT_BY_ID, viewCheckoutById, viewCheckoutByIdHandler };
