import { DomainEvent, MessageName } from "@lookiero/messaging";
import {
  CheckoutFeedbackGiven,
  CHECKOUT_FEEDBACK_GIVEN,
} from "../../../../domain/checkoutFeedback/model/checkoutFeedbackGiven";
import { HttpCheckoutFeedbacksSaveFunction } from "./httpCheckoutFeedbacks";

interface HttpCheckoutFeedbackGiveFunction extends HttpCheckoutFeedbacksSaveFunction {}

const isCheckoutFeedbackGiven = (event: DomainEvent<MessageName>): event is CheckoutFeedbackGiven =>
  event.name === CHECKOUT_FEEDBACK_GIVEN;

const httpCheckoutFeedbacksGive: HttpCheckoutFeedbackGiveFunction =
  ({ httpPost }) =>
  async ({ checkoutId, feedbacks, domainEvents }) => {
    const checkoutFeedbackGiven = domainEvents.find(isCheckoutFeedbackGiven);

    if (!checkoutFeedbackGiven) {
      return;
    }

    await httpPost({
      endpoint: "/give-checkout-feedback",
      body: { checkoutId, feedbacks },
    });
  };

export { httpCheckoutFeedbacksGive };
