import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_ITEM_REPLACED = "checkout_item_replaced";

interface CheckoutItemReplacedPayload {
  readonly aggregateId: string;
}

interface CheckoutItemReplaced extends DomainEvent<typeof CHECKOUT_ITEM_REPLACED>, CheckoutItemReplacedPayload {}

interface CheckoutItemReplacedFunction {
  (payload: CheckoutItemReplacedPayload): CheckoutItemReplaced;
}

const checkoutItemReplaced: CheckoutItemReplacedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_ITEM_REPLACED });

const isCheckoutItemReplaced = (event: DomainEvent<MessageName>): event is CheckoutItemReplaced =>
  event.name === CHECKOUT_ITEM_REPLACED;

export type { CheckoutItemReplaced };
export { CHECKOUT_ITEM_REPLACED, checkoutItemReplaced, isCheckoutItemReplaced };
