import { useEffect, useState } from "react";
import { Variation, isExcludedVariation } from "../Kameleoon";
import { UseAssignedVariationByExperimentIdFunction } from "./useAssignedVariationByExperimentId";
import { useKameleoon } from "./useKameleoon";

const useAssignedVariationByExperimentId: UseAssignedVariationByExperimentIdFunction = ({
  experimentId,
  enabled = true,
}) => {
  const kameleoon = useKameleoon();
  const [assignedVariation, setAssignedVariation] = useState<Variation | undefined | null>(enabled ? undefined : null);

  useEffect(() => {
    if (!(kameleoon && enabled)) {
      return;
    }

    const variation = kameleoon.assignedVariantByExperimentId({ experimentId });
    const isActiveVariation = variation && !isExcludedVariation(variation);

    setAssignedVariation(isActiveVariation ? variation : null);
  }, [enabled, experimentId, kameleoon]);

  return { assignedVariation };
};

export { useAssignedVariationByExperimentId };
