import React, { FC } from "react";
import { View, ViewProps } from "react-native";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { style } from "./Row.style";

interface RowProps extends ViewProps {}

const Row: FC<RowProps> = ({ children, style: customStyle, ...restProps }) => {
  const screenSize = useScreenSize();
  const largeStyle = screenSize !== "S" && style.rowLarge;

  return (
    <View style={[style.row, largeStyle, customStyle]} {...restProps}>
      {children}
    </View>
  );
};

export { Row };
