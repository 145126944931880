import { WINDOW } from "@sentry/browser";
import { Transaction, TransactionContext } from "@sentry/types";
import { logger } from "@sentry/utils";
import React from "react";
import { RouteObject } from "react-router-native";
import {
  Location,
  MatchRoutes,
  NavigationType,
  UseEffect,
  UseLocation,
  UseNavigationType,
  WrapUseRoutes,
} from "../types";
import { stripFirstSlugFromLocation } from "./location";
import { normalizedNameAndMatchForLocation } from "./normalizedNameAndMatchForLocation";

/**
 * "@sentry/react"'s reactRouterV6Instrumentation has a bug and we have to patch its implementation.
 * (regarding location and matchRoutes)
 */

type TransactionSource = "url" | "route";

interface NavigationTransactionContext extends TransactionContext {
  readonly tags: {
    readonly ["routing.instrumentation"]: string;
    readonly ["routing.route.name"]: string;
  };
  readonly metadata: {
    readonly source: TransactionSource;
  };
}

interface TransactionContextFunctionArgs {
  readonly instrumentationName: string;
  readonly op: string;
  readonly routeName: string;
  readonly source: TransactionSource;
}

interface TransactionContextFunction {
  (args: TransactionContextFunctionArgs): NavigationTransactionContext;
}

const transactionContext: TransactionContextFunction = ({
  instrumentationName,
  op,
  routeName,
  source,
}): NavigationTransactionContext => ({
  name: routeName,
  op,
  tags: {
    ["routing.instrumentation"]: instrumentationName,
    ["routing.route.name"]: routeName,
  },
  metadata: {
    source,
  },
});

const instrumentationName = "react-router-v6";

let activeTransaction: Transaction | undefined;

/* eslint-disable @typescript-eslint/naming-convention */
let _useEffect: UseEffect;
let _useLocation: UseLocation;
let _useNavigationType: UseNavigationType;
let _matchRoutes: MatchRoutes;
let _customStartTransaction: (context: TransactionContext) => Transaction | undefined;
let _startTransactionOnLocationChange: boolean;
let _transformLocation: (location: Location) => Location;
/* eslint-enable @typescript-eslint/naming-convention */

interface ReactRouterV6InstrumentationFunctionArgs {
  readonly useEffect: UseEffect;
  readonly useLocation: UseLocation;
  readonly useNavigationType: UseNavigationType;
  readonly matchRoutes: MatchRoutes;
  readonly transformLocation?: (location: Location) => Location;
}

interface ReactRouterV6InstrumentationFunction {
  (
    args: ReactRouterV6InstrumentationFunctionArgs,
  ): (
    customStartTransaction: (context: TransactionContext) => Transaction | undefined,
    startTransactionOnPageLoad?: boolean,
    startTransactionOnLocationChange?: boolean,
  ) => void;
}

const reactRouterV6Instrumentation: ReactRouterV6InstrumentationFunction = ({
  useEffect,
  useLocation,
  useNavigationType,
  matchRoutes,
  transformLocation = stripFirstSlugFromLocation,
}) => {
  return (customStartTransaction, startTransactionOnPageLoad = true, startTransactionOnLocationChange = true): void => {
    const initPathName = WINDOW && WINDOW.location && WINDOW.location.pathname;

    if (startTransactionOnPageLoad && initPathName) {
      activeTransaction = customStartTransaction(
        transactionContext({ instrumentationName, op: "pageload", routeName: initPathName, source: "url" }),
      );
    }

    _useEffect = useEffect;
    _useLocation = useLocation;
    _useNavigationType = useNavigationType;
    _matchRoutes = matchRoutes;
    _customStartTransaction = customStartTransaction;
    _startTransactionOnLocationChange = startTransactionOnLocationChange;
    _transformLocation = transformLocation;
  };
};

const updatePageloadTransaction = (location: Location, routes: RouteObject[]): void => {
  const branches = _matchRoutes(routes, location);

  if (activeTransaction && branches) {
    const [name, match] = normalizedNameAndMatchForLocation({ routes, location, branches });
    const source = match ? "route" : "url";
    activeTransaction.setName(name, source);
  }
};

const handleNavigation = (location: Location, routes: RouteObject[], navigationType: NavigationType): void => {
  const branches = _matchRoutes(routes, location);

  if (_startTransactionOnLocationChange && (navigationType === "PUSH" || navigationType === "POP") && branches) {
    if (activeTransaction) {
      activeTransaction.finish();
    }

    const [name, match] = normalizedNameAndMatchForLocation({ routes, location, branches });
    const source = match ? "route" : "url";

    activeTransaction = _customStartTransaction(
      transactionContext({ instrumentationName, op: "navigation", routeName: name, source }),
    );
  }
};

const wrapUseRoutes: WrapUseRoutes = (origUseRoutes) => {
  if (!_useEffect || !_useLocation || !_useNavigationType || !_matchRoutes || !_customStartTransaction) {
    logger.warn(
      "reactRouterV6Instrumentation was unable to wrap `useRoutes` because of one or more missing parameters.",
    );

    return origUseRoutes;
  }

  let isMountRenderPass = true;

  // eslint-disable-next-line react/display-name
  return (routes, locationArg): React.ReactElement | null => {
    const SentryRoutes: React.FC<unknown> = () => {
      const Routes = origUseRoutes(routes, locationArg);

      const location = _useLocation();
      const navigationType = _useNavigationType();

      // A value with stable identity to either pick `locationArg` if available or `location` if not
      const stableLocationParam =
        typeof locationArg === "string" || (locationArg && locationArg.pathname)
          ? (locationArg as { pathname: string })
          : location;

      _useEffect(() => {
        const normalizedLocation =
          typeof stableLocationParam === "string" ? { pathname: stableLocationParam } : stableLocationParam;

        if (isMountRenderPass) {
          updatePageloadTransaction(_transformLocation(normalizedLocation), routes);
          isMountRenderPass = false;
        } else {
          handleNavigation(_transformLocation(normalizedLocation), routes, navigationType);
        }
      }, [navigationType, stableLocationParam]);

      return Routes;
    };

    return <SentryRoutes />;
  };
};

export { reactRouterV6Instrumentation, wrapUseRoutes };
