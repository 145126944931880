import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

type FiveItemsDiscountByCustomerIdProjection = number;

const VIEW_FIVE_ITEMS_DISCOUNT_BY_CUSTOMER_ID = "view_five_items_discount_by_customer_id";

interface ViewFiveItemsDiscountByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewFiveItemsDiscountByCustomerId
  extends Query<typeof VIEW_FIVE_ITEMS_DISCOUNT_BY_CUSTOMER_ID>,
    ViewFiveItemsDiscountByCustomerIdPayload {}

interface ViewFiveItemsDiscountByCustomerIdFunction {
  (payload: ViewFiveItemsDiscountByCustomerIdPayload): ViewFiveItemsDiscountByCustomerId;
}

const viewFiveItemsDiscountByCustomerId: ViewFiveItemsDiscountByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_FIVE_ITEMS_DISCOUNT_BY_CUSTOMER_ID }),
  ...payload,
});

interface FiveItemsDiscountByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface FiveItemsDiscountByCustomerIdView {
  (args: FiveItemsDiscountByCustomerIdViewArgs): Promise<FiveItemsDiscountByCustomerIdProjection>;
}

interface ViewFiveItemsDiscountByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: FiveItemsDiscountByCustomerIdView;
}

const viewFiveItemsDiscountByCustomerIdHandler: QueryHandlerFunction<
  ViewFiveItemsDiscountByCustomerId,
  FiveItemsDiscountByCustomerIdProjection,
  ViewFiveItemsDiscountByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { FiveItemsDiscountByCustomerIdProjection, FiveItemsDiscountByCustomerIdView };
export {
  VIEW_FIVE_ITEMS_DISCOUNT_BY_CUSTOMER_ID,
  viewFiveItemsDiscountByCustomerId,
  viewFiveItemsDiscountByCustomerIdHandler,
};
