import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { KeepCheckoutItem } from "../command/keepCheckoutItem";
import { ReplaceCheckoutItem } from "../command/replaceCheckoutItem";
import { ResetCheckoutItem } from "../command/resetCheckoutItem";
import { ReturnCheckoutItem } from "../command/returnCheckoutItem";
import { checkoutItemKept } from "./checkoutItemKept";
import { checkoutItemReplaced } from "./checkoutItemReplaced";
import { checkoutItemReset } from "./checkoutItemReset";
import { checkoutItemReturned } from "./checkoutItemReturned";
import { Feedbacks } from "./feedbacks";
import { Price } from "./price";

enum CheckoutItemStatus {
  INITIAL = "INITIAL",
  KEPT = "KEPT",
  RETURNED = "RETURNED",
  REPLACED = "REPLACED",
}

interface CheckoutItem extends AggregateRoot {
  readonly status: CheckoutItemStatus;
  readonly price: Price;
  readonly feedbacks: Feedbacks;
  readonly replacedForId?: string;
}

const keepCheckoutItemHandler: CommandHandlerFunction<KeepCheckoutItem, CheckoutItem> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;

    return {
      ...aggregateRoot,
      status: CheckoutItemStatus.KEPT,
      domainEvents: [checkoutItemKept({ aggregateId })],
    };
  };

const returnCheckoutItemHandler: CommandHandlerFunction<ReturnCheckoutItem, CheckoutItem> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, feedbacks } = command;

    return {
      ...aggregateRoot,
      feedbacks,
      status: CheckoutItemStatus.RETURNED,
      domainEvents: [checkoutItemReturned({ aggregateId })],
    };
  };

const replaceCheckoutItemHandler: CommandHandlerFunction<ReplaceCheckoutItem, CheckoutItem> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, replacedForId } = command;

    return {
      ...aggregateRoot,
      replacedForId,
      status: CheckoutItemStatus.REPLACED,
      domainEvents: [checkoutItemReplaced({ aggregateId })],
    };
  };

const resetCheckoutItemHandler: CommandHandlerFunction<ResetCheckoutItem, CheckoutItem> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;

    return {
      ...aggregateRoot,
      status: CheckoutItemStatus.INITIAL,
      domainEvents: [checkoutItemReset({ aggregateId })],
    };
  };

export type { CheckoutItem };
export {
  CheckoutItemStatus,
  keepCheckoutItemHandler,
  returnCheckoutItemHandler,
  replaceCheckoutItemHandler,
  resetCheckoutItemHandler,
};
