import { command, Command } from "@lookiero/messaging";

const RESET_CHECKOUT_ITEM = "reset_checkout_item";

interface ResetCheckoutItemPayload {
  readonly aggregateId: string;
}

interface ResetCheckoutItem extends Command<typeof RESET_CHECKOUT_ITEM>, ResetCheckoutItemPayload {}

interface ResetCheckoutItemFunction {
  (payload: ResetCheckoutItemPayload): ResetCheckoutItem;
}

const resetCheckoutItem: ResetCheckoutItemFunction = ({ aggregateId }) =>
  command({ aggregateId, name: RESET_CHECKOUT_ITEM });

export type { ResetCheckoutItem };
export { RESET_CHECKOUT_ITEM, resetCheckoutItem };
