import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { Logger } from "../../../../../logging/Logger";
import { createModalNotification } from "../../../../domain/notification/command/createModalNotification";

interface CreateModalNotificationFunctionArgs {
  readonly aggregateId?: string;
  readonly bodyI18nKey: string;
  readonly titleI18nKey: string;
  readonly acceptI18nKey: string;
}

interface CreateModalNotificationFunction {
  (args: CreateModalNotificationFunctionArgs): Promise<void>;
}

interface UseCreateModalNotificationFunctionArgs {
  readonly contextId: string;
  readonly logger: Logger;
}

interface UseCreateModalNotificationFunction {
  (args: UseCreateModalNotificationFunctionArgs): [create: CreateModalNotificationFunction, status: CommandStatus];
}

const useCreateModalNotification: UseCreateModalNotificationFunction = ({ contextId, logger }) => {
  const [commandBus, status] = useCommand({ contextId });

  const create: CreateModalNotificationFunction = useCallback(
    async ({ aggregateId, titleI18nKey, bodyI18nKey, acceptI18nKey }) => {
      try {
        await commandBus(
          createModalNotification({
            aggregateId: aggregateId || uuid(),
            titleI18nKey,
            bodyI18nKey,
            acceptI18nKey,
          }),
        );
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [commandBus, logger],
  );

  return [create, status];
};

export { useCreateModalNotification };
