import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useRef } from "react";
import invariant from "tiny-invariant";
import { Country } from "../../../projection/shared/country";
import { useTrackAssignedVariationByExperiment } from "../../../shared/tracking/infrastructure/useTrackAssignedVariationByExperiment";
import { Spinner } from "../../../shared/ui/components/atoms/spinner/Spinner";
import { useViewFirstAvailableCheckoutByCustomerId } from "../../projection/checkout/react/useViewFirstAvailableCheckoutByCustomerId";
import { useAssignedVariationByExperimentId } from "../../testing/react/useAssignedVariationByExperimentId";
import { useStaticInfo } from "./useStaticInfo";

enum NewFeedbackExperimentVariation {
  CONTROL = "control",
  RETURN_PAGE = "return_page",
}

interface NewFeedbackExperiment {
  readonly variation: NewFeedbackExperimentVariation;
}

const NewFeedbackExperimentContext = createContext<NewFeedbackExperiment>(null as unknown as NewFeedbackExperiment);

interface NewFeedbackExperimentProviderProps {
  readonly country: Country;
  readonly customerId: string;
  readonly children: ReactNode;
}

const NewFeedbackExperimentProvider: FC<NewFeedbackExperimentProviderProps> = ({ customerId, country, children }) => {
  const {
    kameleoon: {
      experiments: {
        newFeedback: { id: newFeedbackId, variations },
      },
    },
  } = useStaticInfo();
  const [checkout] = useViewFirstAvailableCheckoutByCustomerId({ customerId });
  const { assignedVariation: newFeedbackVariation } = useAssignedVariationByExperimentId({
    experimentId: newFeedbackId,
  });
  const trackAssignedVariation = useTrackAssignedVariationByExperiment({
    checkoutId: checkout?.id,
    country,
    experimentId: newFeedbackId,
  });

  const value = useMemo(
    () => ({
      variation:
        newFeedbackVariation?.id === Number(variations.v1)
          ? NewFeedbackExperimentVariation.RETURN_PAGE
          : NewFeedbackExperimentVariation.CONTROL,
    }),
    [newFeedbackVariation?.id, variations.v1],
  );

  const assignedVariantTracked = useRef(false);
  useEffect(() => {
    if (assignedVariantTracked.current || !newFeedbackVariation || !checkout?.id) {
      return;
    }

    trackAssignedVariation({ assignedVariation: newFeedbackVariation });
    assignedVariantTracked.current = true;
  }, [checkout?.id, newFeedbackVariation, trackAssignedVariation]);

  if (!checkout) {
    return <Spinner />;
  }

  return <NewFeedbackExperimentContext.Provider value={value}>{children}</NewFeedbackExperimentContext.Provider>;
};

const useNewFeedbackExperiment = () => {
  const newFeedbackExperiment = useContext(NewFeedbackExperimentContext);

  invariant(
    newFeedbackExperiment,
    "Your are trying to use the useNewFeedbackExperiment hook without wrapping your app with the <NewFeedbackExperimentProvider>.",
  );

  return newFeedbackExperiment.variation;
};

export { useNewFeedbackExperiment, NewFeedbackExperimentProvider, NewFeedbackExperimentVariation };
