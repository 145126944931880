import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { Notification } from "../../../../domain/notification/model/notification";
import {
  NotificationsGetFunction,
  NotificationsSaveFunction,
} from "../../../../domain/notification/model/notifications";
import { NotificationDto } from "../../../persistence/notificationDto";
import { Storage } from "../../../persistence/storage";
import { storageNotificationsCreate } from "./storageNotificationsCreate";
import { storageNotificationsRemove } from "./storageNotificationsRemove";

const toDomain = ({
  aggregateId,
  type,
  level,
  titleI18nKey,
  bodyI18nKey,
  acceptI18nKey,
}: NotificationDto): Notification => ({
  aggregateId,
  type,
  level,
  titleI18nKey,
  bodyI18nKey,
  acceptI18nKey,
  domainEvents: [],
});

interface StorageNotificationsGetFunctionArgs extends RepositoryGetFunctionArgs {
  readonly storage: Storage<NotificationDto>;
}

interface StorageNotificationsGetFunction extends NotificationsGetFunction<StorageNotificationsGetFunctionArgs> {}

const getNotification: StorageNotificationsGetFunction =
  ({ storage }) =>
  async (aggregateId) => {
    const notificationDto = await storage.read(aggregateId);

    if (!notificationDto) {
      throw new Error(`Notification not found: ${aggregateId}`);
    }

    return toDomain(notificationDto);
  };

interface StorageNotificationsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly storage: Storage<NotificationDto>;
}

interface StorageNotificationsSaveFunction extends NotificationsSaveFunction<StorageNotificationsSaveFunctionArgs> {}

const saveNotification: StorageNotificationsSaveFunction =
  ({ storage }) =>
  async (aggregateRoot) => {
    await Promise.all([
      storageNotificationsCreate({ storage })(aggregateRoot),
      storageNotificationsRemove({ storage })(aggregateRoot),
    ]);
  };

export type { StorageNotificationsSaveFunction };
export { getNotification, saveNotification };
