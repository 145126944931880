import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_ITEM_KEPT = "checkout_item_kept";

interface CheckoutItemKeptPayload {
  readonly aggregateId: string;
}

interface CheckoutItemKept extends DomainEvent<typeof CHECKOUT_ITEM_KEPT>, CheckoutItemKeptPayload {}

interface CheckoutItemKeptFunction {
  (payload: CheckoutItemKeptPayload): CheckoutItemKept;
}

const checkoutItemKept: CheckoutItemKeptFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_ITEM_KEPT });

const isCheckoutItemKept = (event: DomainEvent<MessageName>): event is CheckoutItemKept =>
  event.name === CHECKOUT_ITEM_KEPT;

export type { CheckoutItemKept };
export { CHECKOUT_ITEM_KEPT, checkoutItemKept, isCheckoutItemKept };
