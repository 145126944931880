import { Aurora, Text } from "@lookiero/aurora";
import { EventProvider } from "@lookiero/event";
import { EndpointFunction } from "@lookiero/i18n";
import { i18n } from "@lookiero/i18n-react";
import { PaymentsQueryProvider, setPaymentsBridge } from "@lookiero/payments-front";
import { useFonts } from "expo-font";
import "expo/build/Expo.fx";
import React, { FC, useCallback, useState } from "react";
import { Platform, ScrollView } from "react-native";
import "react-native-get-random-values";
import { Navigate, Route, Routes } from "react-router-native";
import packageJson from "../package.json";
// import { bootstrap as checkoutMockBootstrap } from "./infrastructure/delivery/bootstrap.mock";
import { bootstrap as checkoutBootstrap } from "./infrastructure/delivery/bootstrap";
import { KameleoonEnvironment } from "./infrastructure/testing/kameleoonEnvironment";
import { root } from "./infrastructure/ui/Root";
import { DummyLayout } from "./infrastructure/ui/components/layouts/layout/dummyLayout/DummyLayout";
import { fetchTranslations } from "./infrastructure/ui/i18n/fetchTranslations";
import { translationExternalEndpoint } from "./infrastructure/ui/i18n/translationEndpoint";
import { Router } from "./infrastructure/ui/routing/router/Router";
import { Country } from "./projection/shared/country";
import { SentryEnvironment } from "./shared/logging/SentryLogger";

const sentryConfig: SentryEnvironment = {
  publicKey: "66cadf9444db4ea5945670f12ec08ae7",
  release: packageJson.version,
  project: "4504400729276416",
  environment: `${Platform.OS}-EXPO`,
};

const apiUrl =
  Platform.OS !== "web" ? "https://web2.psp.dev.aws.lookiero.es/checkout/api" : __DEV__ ? "/local-to-dev" : "/api";
const authToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjUwNDY3MDcsImV4cCI6MTcxOTc0MTYzMCwiZGlzcGxheU5hbWUiOiJNaWtlbCIsImNvdW50cnlfY29kZSI6IkVTIiwiYWNjZXNzVmlhIjoiZW1haWwiLCJzdWJzY3JpcHRpb25TdGFydGluZ0RhdGUiOiIyMDI0LTA1LTI5IiwiaW1wZXJzb25hdGVkIjpmYWxzZSwidXVpZCI6IjUxYTAzMWIxLTAxZTYtNDcxNy05N2U1LTE1N2ViNWE5Njk1MyIsImlhdCI6MTcxNzA2MzIzMH0.k8T3SdiOVu0UkRqPI6QlFDldI9Dgi0p0nT0i9dwc2lU";
const getAuthToken = () => Promise.resolve(authToken);

const externalTranslationsUrl =
  Platform.OS !== "web"
    ? "https://backend-for-user.dev.envs.lookiero.tech/api/v2/translations"
    : __DEV__
      ? "/local-to-external-i18n"
      : "/external-i18n";
const translations: EndpointFunction[] = [
  (locale) =>
    translationExternalEndpoint({
      translationsUrl: externalTranslationsUrl,
      projects: [["user-area-front"], ["inventory-catalog", "feature;feature_value;Color"], ["checkout"]],
    })(locale),
];

const useRedirect: () => Record<string, string> = () => ({
  returnUrl: "https://web2.dev.aws.lookiero.es/user/",
});

setPaymentsBridge({
  getToken: getAuthToken,
  stripeKey: "pk_test_6dwdUfkUm7AaUH3oSje9H0kk",
  stripeUkKey:
    "pk_test_51Jw6NEHQ1gSUXaQ8KKBLBhmzw7k0TCTN0GQ6qq8oGRmXCn09ptn7zrHX5jsyidv8iBDGuzUDV3R0fko01m5oJzMO00z6XmoLbn",
  commonAssetsPath: "https://cdn.dev.envs.lookiero.tech/commons",
  paymentsAssetsPath: "https://cdn.dev.envs.lookiero.tech/payments-front",
  useAvoidKeyboard: () => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAvoidKeyboard: () => void 0,
  }),
  emitTrackingEvent: (e) => {
    console.log("Tracking Payment event", e);
  },
  appVersion: packageJson.version,
  graphqlUri: "/graphql",
  googlePay: {
    merchant: {
      merchantId: "0123456789",
      merchantName: "Lookiero",
    },
    isTestEnv: true,
  },
  useFeatureFlags: () => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    ALLOW_GOOGLE_PAY_AT: true,
    ALLOW_GOOGLE_PAY_BE: true,
    ALLOW_GOOGLE_PAY_DE: true,
    ALLOW_GOOGLE_PAY_ES: true,
    ALLOW_GOOGLE_PAY_FR: true,
    ALLOW_GOOGLE_PAY_GB: true,
    ALLOW_GOOGLE_PAY_IT: true,
    ALLOW_GOOGLE_PAY_LU: true,
    ALLOW_GOOGLE_PAY_NL: true,
    ALLOW_GOOGLE_PAY_PT: true,
    ALLOW_GOOGLE_PAY_APP_AT: true,
    ALLOW_GOOGLE_PAY_APP_BE: true,
    ALLOW_GOOGLE_PAY_APP_DE: true,
    ALLOW_GOOGLE_PAY_APP_ES: true,
    ALLOW_GOOGLE_PAY_APP_FR: true,
    ALLOW_GOOGLE_PAY_APP_GB: true,
    ALLOW_GOOGLE_PAY_APP_IT: true,
    ALLOW_GOOGLE_PAY_APP_LU: true,
    ALLOW_GOOGLE_PAY_APP_NL: true,
    ALLOW_GOOGLE_PAY_APP_PT: true,
    /* eslint-enable @typescript-eslint/naming-convention */
  }),
  locale: () => Promise.resolve("es-ES"),
  scrollView: ScrollView,
});

const kameleoonConfig: KameleoonEnvironment = {
  siteCode: "aplm4v3ckn",
  experiments: {
    newFeedback: {
      id: "245000",
      variations: {
        v1: "964072",
      },
    },
  },
};

// const { Component: Messaging } = checkoutMockBootstrap();
const { Component: Messaging } = checkoutBootstrap({ apiUrl: () => apiUrl, getAuthToken });
const I18n = i18n({
  fetchTranslation: fetchTranslations({ translations }),
  contextId: "CheckoutI18n",
});
const Root = root({
  Messaging,
  I18n,
  getAuthToken,
  development: false,
  sentry: () => sentryConfig,
  kameleoon: () => kameleoonConfig,
});

const ExpoRoot: FC = () => {
  const [fontsLoaded] = useFonts({
    ["AreaInktrap-Semibold"]: require("@lookiero/aurora-fonts/AreaInktrap-Semibold.otf"),
    ["AreaNormal-Semibold"]: require("@lookiero/aurora-fonts/AreaNormal-Semibold.otf"),
    ["AreaNormal-Extrabold"]: require("@lookiero/aurora-fonts/AreaNormal-Extrabold.otf"),
    auroraicons: require("@lookiero/aurora-iconfont/dist/auroraicons.ttf"),
  });

  const [isAccessible, setIsAccessible] = useState<boolean>();
  const onNotAccessible = useCallback(() => setIsAccessible(false), []);

  return fontsLoaded ? (
    <PaymentsQueryProvider>
      <EventProvider>
        <Aurora>
          {isAccessible === false && <Text heading={true}>Checkout is not accessible!</Text>}

          <Router>
            <Routes>
              <Route
                path="/checkout/*"
                element={
                  <Root
                    basePath="/checkout"
                    layout={DummyLayout}
                    locale="es"
                    subscription="b"
                    useRedirect={useRedirect}
                    customer={{
                      customerId: "51a031b1-01e6-4717-97e5-157eb5a96953",
                      country: Country.ES,
                    }}
                    order={{
                      isFirstOrder: false,
                      orderNumber: 3687582,
                      coupon: "MYLOOKIERO",
                    }}
                    onNotAccessible={onNotAccessible}
                  />
                }
              />

              <Route element={<Navigate to="/checkout" replace />} path="*" />
            </Routes>
          </Router>
        </Aurora>
      </EventProvider>
    </PaymentsQueryProvider>
  ) : null;
};

export { ExpoRoot };
