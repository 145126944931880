import { StyleSheet } from "react-native";

const style = StyleSheet.create({
  stack: {
    flexDirection: "column",
    width: "100%",
  },
  stackItem: {
    width: "100%",
  },
});

export { style };
