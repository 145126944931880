import React, { FC, useEffect, useState, ReactElement } from "react";
import { useLogger } from "../../../shared/logging/useLogger";
import { asyncKameleoon } from "../AsyncKameleoon";
import { Kameleoon as KameleoonInstance } from "../Kameleoon";
import { KameleoonProps } from "./Kameleoon";
import { KameleoonProvider } from "./useKameleoon";

const Kameleoon: FC<KameleoonProps> = ({ siteCode, loader = null, children }) => {
  const logger = useLogger();
  const [kameleoon, setKameleoon] = useState<KameleoonInstance>();

  useEffect(() => {
    const loadKameleoon = async () => setKameleoon(await asyncKameleoon({ siteCode, logger }));

    loadKameleoon();
  }, [logger, siteCode]);

  return kameleoon ? (
    <KameleoonProvider kameleoon={kameleoon}>{children}</KameleoonProvider>
  ) : (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (loader as ReactElement<any, any> | null)
  );
};

export { Kameleoon };
