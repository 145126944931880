import { DomainEvent, MessageName } from "@lookiero/messaging";
import { CheckoutStarted, CHECKOUT_STARTED } from "../../../../domain/checkout/model/checkoutStarted";
import { HttpCheckoutsSaveFunction } from "./httpCheckouts";

interface HttpCheckoutsStartFunction extends HttpCheckoutsSaveFunction {}

const isCheckoutStarted = (event: DomainEvent<MessageName>): event is CheckoutStarted =>
  event.name === CHECKOUT_STARTED;

const httpCheckoutsStart: HttpCheckoutsStartFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const checkoutStarted = domainEvents.find(isCheckoutStarted);

    if (!checkoutStarted) {
      return;
    }

    await httpPost({
      endpoint: "/start-checkout",
      body: { checkoutId: aggregateId },
    });
  };

export { httpCheckoutsStart };
