import { command, Command } from "@lookiero/messaging";

const SUBMIT_CHECKOUT = "submit_checkout";

interface SubmitCheckoutPayload {
  readonly aggregateId: string;
}

interface SubmitCheckout extends Command<typeof SUBMIT_CHECKOUT>, SubmitCheckoutPayload {}

interface SubmitCheckoutFunction {
  (payload: SubmitCheckoutPayload): SubmitCheckout;
}

const submitCheckout: SubmitCheckoutFunction = ({ aggregateId }) => command({ aggregateId, name: SUBMIT_CHECKOUT });

export type { SubmitCheckout };
export { SUBMIT_CHECKOUT, submitCheckout };
