import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import invariant from "tiny-invariant";
import { CheckoutBooking } from "../../../../domain/checkoutBooking/model/checkoutBooking";
import {
  CheckoutBookingsGetFunction,
  CheckoutBookingsSaveFunction,
} from "../../../../domain/checkoutBooking/model/checkoutBookings";
import {
  viewCheckoutBookingById,
  ViewCheckoutBookingById,
  ViewCheckoutBookingByIdResult,
} from "../../../../projection/checkoutBooking/viewCheckoutBookingById";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpCheckoutBookingsBlock } from "./httpCheckoutBookingsBlock";
import { httpCheckoutBookingsBook } from "./httpCheckoutBookingsBook";

interface ToDomainFunction {
  (checkoutBooking: ViewCheckoutBookingByIdResult): CheckoutBooking | never;
}

const toDomain: ToDomainFunction = (checkoutBooking) => {
  invariant(checkoutBooking, "No checkout booking found!");

  return {
    aggregateId: checkoutBooking.id,
    checkoutItemIds: checkoutBooking.checkoutItemIds,
    isExpired: checkoutBooking.isExpired,
    domainEvents: [],
  };
};

interface HttpCheckoutBookingsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpCheckoutBookingsGetFunction extends CheckoutBookingsGetFunction<HttpCheckoutBookingsGetFunctionArgs> {}

const getCheckoutBooking: HttpCheckoutBookingsGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(
      await queryBus<ViewCheckoutBookingById, ViewCheckoutBookingByIdResult>(
        viewCheckoutBookingById({ checkoutBookingId: aggregateId }),
      ),
    );

interface HttpCheckoutBookingsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutBookingsSaveFunction extends CheckoutBookingsSaveFunction<HttpCheckoutBookingsSaveFunctionArgs> {}

const saveCheckoutBooking: HttpCheckoutBookingsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([
      httpCheckoutBookingsBook({ httpPost })(aggregateRoot),
      httpCheckoutBookingsBlock({ httpPost })(aggregateRoot),
    ]);
  };

export type { HttpCheckoutBookingsSaveFunction };
export { getCheckoutBooking, saveCheckoutBooking };
