import { command, Command } from "@lookiero/messaging";

const BLOCK_CHECKOUT_BOOKING = "block_checkout_booking";

interface BlockCheckoutBookingPayload {
  readonly aggregateId: string;
}

interface BlockCheckoutBooking extends Command<typeof BLOCK_CHECKOUT_BOOKING>, BlockCheckoutBookingPayload {}

interface BlockCheckoutBookingFunction {
  (payload: BlockCheckoutBookingPayload): BlockCheckoutBooking;
}

const blockCheckoutBooking: BlockCheckoutBookingFunction = ({ aggregateId }) =>
  command({ aggregateId, name: BLOCK_CHECKOUT_BOOKING });

export type { BlockCheckoutBooking };
export { BLOCK_CHECKOUT_BOOKING, blockCheckoutBooking };
