import {
  IsCheckoutEnabledByCustomerIdProjection,
  IsCheckoutEnabledByCustomerIdView,
} from "../../../projection/checkout/viewIsCheckoutEnabledByCustomerId";
import { HttpGetFunction } from "../../delivery/http/httpClient";

interface HttpIsCheckoutEnabledByCustomerIdView extends IsCheckoutEnabledByCustomerIdView {}

interface HttpIsCheckoutEnabledByCustomerIdViewFunctionArgs {
  readonly httpGet: HttpGetFunction;
}

interface HttpIsCheckoutEnabledByCustomerIdViewFunction {
  (args: HttpIsCheckoutEnabledByCustomerIdViewFunctionArgs): HttpIsCheckoutEnabledByCustomerIdView;
}

const httpIsCheckoutEnabledByCustomerIdView: HttpIsCheckoutEnabledByCustomerIdViewFunction =
  ({ httpGet }) =>
  async ({ customerId, signal }) =>
    await httpGet<IsCheckoutEnabledByCustomerIdProjection>({
      endpoint: `/is-new-checkout-enabled/${customerId}`,
      signal,
      result: {
        error: false,
        success: (response) => response,
      },
    });

export { httpIsCheckoutEnabledByCustomerIdView };
