import { command, Command } from "@lookiero/messaging";

const KEEP_CHECKOUT_ITEM = "keep_checkout_item";

interface KeepCheckoutItemPayload {
  readonly aggregateId: string;
}

interface KeepCheckoutItem extends Command<typeof KEEP_CHECKOUT_ITEM>, KeepCheckoutItemPayload {}

interface KeepCheckoutItemFunction {
  (payload: KeepCheckoutItemPayload): KeepCheckoutItem;
}

const keepCheckoutItem: KeepCheckoutItemFunction = ({ aggregateId }) =>
  command({ aggregateId, name: KEEP_CHECKOUT_ITEM });

export type { KeepCheckoutItem };
export { KEEP_CHECKOUT_ITEM, keepCheckoutItem };
