import { Footer as AuroraFooter, FooterItem } from "@lookiero/aurora";
import React, { FC } from "react";
import { useLocation } from "react-router-native";

const Footer: FC = () => {
  const location = useLocation();

  return (
    <AuroraFooter value={location.pathname}>
      <FooterItem icon="box" text="Inicio" value="/" />
      <FooterItem icon="heart" text="Look&Like" value="/look-and-like" />
      <FooterItem icon="referrals" text="Amigas" value="/friends" />
      <FooterItem icon="eye_open" text="Inspiración" value="/inspiration" />
      <FooterItem icon="inbox" notification={true} text="Mensajes" value="/messages" />
    </AuroraFooter>
  );
};

export { Footer };
