import React, { createContext, FC, useContext } from "react";
import invariant from "tiny-invariant";

const BasePathContext = createContext<string>(null as unknown as string);

interface BasePathProviderProps {
  readonly children: JSX.Element;
  readonly basePath: string;
}

export const BasePathProvider: FC<BasePathProviderProps> = ({ basePath, children }) => (
  <BasePathContext.Provider value={basePath}>{children}</BasePathContext.Provider>
);

export const useBasePath = () => {
  const basePath = useContext(BasePathContext);

  invariant(
    basePath !== null,
    "Your are trying to use the useBasePath hook without wrapping your app with the <BasePathProvider>.",
  );

  return basePath;
};
