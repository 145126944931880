import { CheckoutItemStatus } from "../../domain/checkoutItem/model/checkoutItem";
import { Currency } from "../../domain/checkoutItem/model/currency";
import { MediaPerspective } from "../../projection/checkoutItem/checkoutItem";
import { Country } from "../../projection/shared/country";
import { Subscription } from "../../projection/shared/subscription";

enum TrackingEvent {
  PAGEVIEW = "pageview",
  KEEP_ITEM = "keep_item",
  RETURN_ITEM = "return_item",
  REPLACE_ITEM = "replace_item",
  RESET_ITEM = "reset_item",
  IMAGE_VIEW = "image_view",
  PRESS_PRICING = "press_pricing",
  PRESS_ITEM = "press_item",
  PRESS_CONTINUE = "press_continue",
  TAB_VIEW = "tab_view",
  CHANGE_FEEDBACK = "change_feedback",
  PRESS_MENU = "press_menu",
  PRESS_BACK = "press_back",
  PRESS_NEXT = "press_next",
  PRESS_PREVIOUS = "press_previous",
  CHECKOUT = "checkout",
  AB_TEST = "abtest",
}

enum TrackingEventCategory {
  NAVIGATION = "navigation",
  ECOMMERCE = "ecommerce",
}

interface BaseTrackingEvent {
  readonly event: TrackingEvent;
  readonly eventCategory: TrackingEventCategory;
  readonly section: string;
  readonly store: Country;
  readonly checkoutId: string;
}

type PageViewTrackingEvent = BaseTrackingEvent;

interface ItemPageViewTrackingEvent extends BaseTrackingEvent {
  readonly checkoutItemId: string;
  readonly status: CheckoutItemStatus;
  readonly productVariantId: string;
  readonly replaceableFor: string;
  readonly unique: boolean;
}

interface KeepItemTrackingEvent extends BaseTrackingEvent {
  readonly checkoutItemId: string;
}

interface ReturnItemTrackingEvent extends BaseTrackingEvent {
  readonly checkoutItemId: string;
}

interface ReplaceItemTrackingEvent extends BaseTrackingEvent {
  readonly checkoutItemId: string;
  readonly productVariantId: string;
  readonly replaceFor: string;
}

interface ResetItemTrackingEvent extends BaseTrackingEvent {
  readonly checkoutItemId: string;
}

interface ImageViewTrackingEvent extends BaseTrackingEvent {
  readonly checkoutItemId: string;
  readonly productVariantId: string;
  readonly perspective: MediaPerspective;
}

interface PressPricingTrackingEvent extends BaseTrackingEvent {
  readonly collapse: boolean;
}

interface PressItemTrackingEvent extends BaseTrackingEvent {
  readonly checkoutItemId: string;
}

type PressContinueTrackingEvent = BaseTrackingEvent;

interface TabViewTrackingEvent<T> extends BaseTrackingEvent {
  readonly tab: T;
}

interface ChangeFeedbackTrackingEvent extends BaseTrackingEvent {
  readonly questionId: string;
  readonly questionName: string;
  readonly feedback: string | undefined;
}

interface PressMenuTrackingEvent extends BaseTrackingEvent {
  readonly collapse: boolean;
}

type PressBackTrackingEvent = BaseTrackingEvent;

interface PressNextTrackingEvent extends BaseTrackingEvent {
  readonly from: string;
  readonly to: string;
}

interface PressPreviousTrackingEvent extends BaseTrackingEvent {
  readonly from: string;
  readonly to: string;
}

interface CheckoutTrackingEvent extends BaseTrackingEvent {
  readonly userId: string;
  readonly sizeChanges: number;
  readonly isFirstOrder: boolean;
  readonly ecommerce: {
    readonly checkout: {
      readonly actionField: {
        readonly items: number;
        readonly currencyCode: Currency;
        readonly subscription: Subscription;
        readonly coupon: string | null;
        readonly orderId: number;
        readonly value: number;
      };
    };
  };
}

interface ABTestTrackingEvent extends BaseTrackingEvent {
  readonly experiment: string;
  readonly variation: string;
}

export type {
  BaseTrackingEvent,
  ChangeFeedbackTrackingEvent,
  CheckoutTrackingEvent,
  ImageViewTrackingEvent,
  ItemPageViewTrackingEvent,
  KeepItemTrackingEvent,
  PageViewTrackingEvent,
  PressContinueTrackingEvent,
  PressItemTrackingEvent,
  PressPricingTrackingEvent,
  ReplaceItemTrackingEvent,
  ResetItemTrackingEvent,
  ReturnItemTrackingEvent,
  TabViewTrackingEvent,
  PressMenuTrackingEvent,
  PressBackTrackingEvent,
  PressNextTrackingEvent,
  PressPreviousTrackingEvent,
  ABTestTrackingEvent,
};
export { TrackingEvent, TrackingEventCategory };
