import React, { FC, ReactNode } from "react";
import { View } from "react-native";
import { style } from "./Header.style";

interface HeaderProps {
  readonly children: ReactNode;
}
const Header: FC<HeaderProps> = ({ children }) => <View style={style.header}>{children}</View>;

export { Header };
