import PropTypes from "prop-types";
import React from "react";
import { Image } from "react-native";
import { LogoSVG } from "./Logo.Base64";

const Logo = () => <Image resizeMode="cover" source={{ uri: LogoSVG }} style={{ height: 48, width: 134 }} />;

Logo.propTypes = {
  light: PropTypes.bool,
};

export { Logo };
