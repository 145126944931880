import AsyncStorage from "@react-native-async-storage/async-storage";
import { ReadFunction, WriteFunction } from "./storage";
import { UiSettingDto } from "./uiSettingData";

const read: ReadFunction<UiSettingDto> = async (key) => {
  const value = await AsyncStorage.getItem(key);

  return value ? JSON.parse(value) : null;
};

const write: WriteFunction<UiSettingDto> = async (key, value) => AsyncStorage.setItem(key, JSON.stringify(value));

export { read, write };
