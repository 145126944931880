import { Spinner as AuroraSpinner } from "@lookiero/aurora";
import React, { FC } from "react";
import { View } from "react-native";
import { style } from "./Spinner.style";

const Spinner: FC = () => (
  <View style={style.container}>
    <AuroraSpinner testID="spinner" />
  </View>
);

export { Spinner };
