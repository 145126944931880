import {
  BookedProductsVariantsForCheckoutItemView,
  ViewBookedProductsVariantsForCheckoutItemResult,
} from "../../../projection/bookedProductsVariants/viewBookedProductVariantsForCheckoutItem";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpBookedProductsVariantsForCheckoutItemView extends BookedProductsVariantsForCheckoutItemView {}

interface HttpBookedProductsVariantsForCheckoutItemViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBookedProductsVariantsForCheckoutItemViewFunction {
  (args: HttpBookedProductsVariantsForCheckoutItemViewFunctionArgs): HttpBookedProductsVariantsForCheckoutItemView;
}

interface ViewBookedProductsVariantsForCheckoutItemResponse {
  readonly result: ViewBookedProductsVariantsForCheckoutItemResult;
}

const httpBookedProductsVariantsForCheckoutItemView: HttpBookedProductsVariantsForCheckoutItemViewFunction =
  ({ httpPost }) =>
  async ({ checkoutItemId, signal }) =>
    await httpPost<ViewBookedProductsVariantsForCheckoutItemResponse, ViewBookedProductsVariantsForCheckoutItemResult>({
      endpoint: "/view-booked-product-variants-for-checkout-item",
      body: { checkoutItemId },
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpBookedProductsVariantsForCheckoutItemView };
