import { useCallback } from "react";
import { Variation } from "../../../infrastructure/testing/Kameleoon";
import { PROJECT } from "../../../infrastructure/tracking/tracking";
import { Country } from "../../../projection/shared/country";
import { ABTestTrackingEvent, TrackingEvent, TrackingEventCategory } from "../tracking";
import { useEmitUserEvent } from "./useEmitUserEvent";

interface TrackAssignedVariationFunctionArgs {
  readonly assignedVariation: Variation;
}

interface TrackAssignedVariationFunction {
  (args: TrackAssignedVariationFunctionArgs): void;
}

interface UseTrackAssignedVariationByExperimentFunctionArgs {
  readonly experimentId: string;
  readonly country: Country;
  readonly checkoutId: string | undefined;
}

interface UseTrackAssignedVariationByExperimentFunction {
  (args: UseTrackAssignedVariationByExperimentFunctionArgs): TrackAssignedVariationFunction;
}

const useTrackAssignedVariationByExperiment: UseTrackAssignedVariationByExperimentFunction = ({
  experimentId,
  country,
  checkoutId,
}) => {
  const emitUserEvent = useEmitUserEvent<ABTestTrackingEvent>();

  const trackAssignedVariation: TrackAssignedVariationFunction = useCallback(
    ({ assignedVariation }) => {
      if (!checkoutId || !assignedVariation) {
        return;
      }

      const abTestTrackingEvent: ABTestTrackingEvent = {
        event: TrackingEvent.AB_TEST,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: PROJECT,
        store: country,
        checkoutId,
        experiment: experimentId,
        variation: String(assignedVariation.id),
      };

      emitUserEvent(abTestTrackingEvent);
    },
    [checkoutId, country, emitUserEvent, experimentId],
  );

  return trackAssignedVariation;
};

export { useTrackAssignedVariationByExperiment };
