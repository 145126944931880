import { DomainEvent, MessageName } from "@lookiero/messaging";
import { CheckoutSubmitted, CHECKOUT_SUBMITTED } from "../../../../domain/checkout/model/checkoutSubmitted";
import { HttpCheckoutsSaveFunction } from "./httpCheckouts";

interface HttpCheckoutsSubmitFunction extends HttpCheckoutsSaveFunction {}

const isCheckoutSubmitted = (event: DomainEvent<MessageName>): event is CheckoutSubmitted =>
  event.name === CHECKOUT_SUBMITTED;

const httpCheckoutsSubmit: HttpCheckoutsSubmitFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents }) => {
    const checkoutSubmitted = domainEvents.find(isCheckoutSubmitted);

    if (!checkoutSubmitted) {
      return;
    }

    await httpPost({
      endpoint: "/submit-checkout",
      body: { checkoutId: aggregateId },
    });
  };

export { httpCheckoutsSubmit };
