import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { GiveCheckoutFeedback } from "../command/giveCheckoutFeedback";
import { checkoutFeedbackGiven } from "./checkoutFeedbackGiven";
import { Feedbacks } from "./feedbacks";

interface CheckoutFeedback extends AggregateRoot {
  readonly checkoutId: string;
  readonly feedbacks: Feedbacks;
}

const giveCheckoutFeedbackHandler: CommandHandlerFunction<GiveCheckoutFeedback, CheckoutFeedback> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, checkoutId, feedbacks } = command;

    return {
      ...aggregateRoot,
      checkoutId,
      feedbacks,
      domainEvents: [checkoutFeedbackGiven({ aggregateId, checkoutId })],
    };
  };

export type { CheckoutFeedback };
export { giveCheckoutFeedbackHandler };
