import { StyleSheet } from "react-native";

const style = StyleSheet.create({
  children: {
    flex: 1,
  },
  safeAreaView: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
});

export { style };
