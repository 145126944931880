import { I18n } from "@lookiero/i18n-react";
import React, { FC, lazy, memo, Suspense } from "react";
import { Navigate, Outlet, useRoutes as reactRouterUseRoutes } from "react-router-native";
import { Country } from "../../../projection/shared/country";
import { Customer } from "../../../projection/shared/customer";
import { Order } from "../../../projection/shared/order";
import { Subscription } from "../../../projection/shared/subscription";
import { Spinner } from "../../../shared/ui/components/atoms/spinner/Spinner";
import { KameleoonEnvironment } from "../../testing/kameleoonEnvironment";
import { Kameleoon } from "../../testing/react/Kameleoon";
import { Layout } from "../components/layouts/layout/Layout";
import { NewFeedbackExperimentProvider } from "../hooks/useNewFeedbackExperiment";
import { StaticInfoProvider } from "../hooks/useStaticInfo";
import { App } from "../views/App";
import { CheckoutPaymentModal } from "../views/checkout/components/checkoutPaymentModal/CheckoutPaymentModal";
import { CheckoutAccessibilityMiddleware } from "./CheckoutAccessibilityMiddleware";
import { CheckoutMiddleware } from "./CheckoutMiddleware";
import { Routes } from "./routes";
import { BasePathProvider } from "./useBasePath";

const Item = lazy(() => import("../views/item/Item").then((module) => ({ default: module.Item })));
const Summary = lazy(() => import("../views/summary/Summary").then((module) => ({ default: module.Summary })));
const SummaryTabs = lazy(() =>
  import("../views/summaryTabs/SummaryTabs").then((module) => ({ default: module.SummaryTabs })),
);
const Checkout = lazy(() => import("../views/checkout/Checkout").then((module) => ({ default: module.Checkout })));
const Feedback = lazy(() => import("../views/feedback/Feedback").then((module) => ({ default: module.Feedback })));
const Return = lazy(() => import("../views/return/Return").then((module) => ({ default: module.Return })));

interface RoutingProps {
  readonly basePath?: string;
  readonly customer: Customer | undefined;
  readonly order: Order | undefined;
  readonly subscription: Subscription | undefined;
  readonly locale: string;
  readonly I18n: I18n;
  readonly kameleoon: KameleoonEnvironment;
  readonly layout: Layout;
  readonly getAuthToken: () => Promise<string>;
  readonly onNotAccessible: () => void;
  readonly onCheckoutSubmitted?: () => void;
  readonly onI18nError?: (err: Error) => void;
  readonly useRedirect: () => Record<string, string>;
  readonly useRoutes: typeof reactRouterUseRoutes;
}

const Routing: FC<RoutingProps> = ({
  basePath = "",
  customer,
  order,
  subscription,
  locale,
  I18n,
  kameleoon,
  layout,
  getAuthToken,
  onI18nError,
  onNotAccessible,
  onCheckoutSubmitted,
  useRedirect,
  useRoutes = reactRouterUseRoutes,
}) => {
  return useRoutes([
    {
      path: "",
      element: (
        <BasePathProvider basePath={basePath}>
          <CheckoutAccessibilityMiddleware customerId={customer?.customerId} onNotAccessible={onNotAccessible}>
            <StaticInfoProvider kameleoon={kameleoon}>
              <I18n loader={<Spinner />} locale={locale} onError={onI18nError}>
                <Kameleoon loader={<Spinner />} siteCode={kameleoon.siteCode}>
                  <CheckoutMiddleware customerId={customer?.customerId as string} onNotAccessible={onNotAccessible}>
                    <NewFeedbackExperimentProvider
                      country={customer?.country as Country}
                      customerId={customer?.customerId as string}
                    >
                      <App>
                        <Outlet />
                      </App>
                    </NewFeedbackExperimentProvider>
                  </CheckoutMiddleware>
                </Kameleoon>
              </I18n>
            </StaticInfoProvider>
          </CheckoutAccessibilityMiddleware>
        </BasePathProvider>
      ),
      children: [
        {
          path: Routes.ITEM,
          element: (
            <Suspense fallback={<Spinner />}>
              <Item
                country={customer?.country as Country}
                customerId={customer?.customerId as string}
                layout={layout}
              />
            </Suspense>
          ),
        },
        {
          path: Routes.ITEM_DETAIL,
          element: (
            <Suspense fallback={<Spinner />}>
              <Item
                country={customer?.country as Country}
                customerId={customer?.customerId as string}
                layout={layout}
              />
            </Suspense>
          ),
        },
        {
          path: Routes.SUMMARY,
          element: (
            <Suspense fallback={<Spinner />}>
              <Summary
                country={customer?.country as Country}
                customerId={customer?.customerId as string}
                layout={layout}
              >
                <Outlet />
              </Summary>
            </Suspense>
          ),
          children: [
            {
              path: Routes.SUMMARY_TABS,
              element: (
                <Suspense fallback={<Spinner />}>
                  <SummaryTabs country={customer?.country as Country} customerId={customer?.customerId as string} />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: Routes.CHECKOUT,
          element: (
            <Suspense fallback={<Spinner />}>
              <Checkout
                country={customer?.country as Country}
                customerId={customer?.customerId as string}
                layout={layout}
                useRedirect={useRedirect}
              >
                <Outlet />
              </Checkout>
            </Suspense>
          ),
          children: [
            {
              path: Routes.CHECKOUT_PAYMENT,
              element: (
                <CheckoutPaymentModal
                  country={customer?.country as Country}
                  coupon={order?.coupon || null}
                  customerId={customer?.customerId as string}
                  getAuthToken={getAuthToken}
                  isFirstOrder={order?.isFirstOrder as boolean}
                  orderNumber={order?.orderNumber as number}
                  subscription={subscription as Subscription}
                  onCheckoutSubmitted={onCheckoutSubmitted}
                />
              ),
            },
          ],
        },
        {
          path: Routes.FEEDBACK,
          element: (
            <Suspense fallback={<Spinner />}>
              <Feedback
                country={customer?.country as Country}
                customerId={customer?.customerId as string}
                layout={layout}
              />
            </Suspense>
          ),
        },
        {
          path: Routes.RETURN,
          element: (
            <Suspense fallback={<Spinner />}>
              <Return
                country={customer?.country as Country}
                customerId={customer?.customerId as string}
                layout={layout}
              />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: <Navigate to={`${Routes.HOME}`} replace />,
        },
      ],
    },
  ]);
};

/**
 * Provided useRoutes is not stable (when integrated with Sentry) as it's rendering a different component tree.
 *
 * https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/reactrouterv6.tsx#L221
 * (SentryRoutes is a new component after each re-render)
 */
const MemoizedRouting = memo(Routing);
export { MemoizedRouting as Routing };
