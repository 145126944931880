import { command, Command } from "@lookiero/messaging";
import { Feedbacks } from "../model/feedbacks";

const GIVE_CHECKOUT_FEEDBACK = "give_checkout_feedback";

interface GiveCheckoutFeedbackPayload {
  readonly checkoutId: string;
  readonly feedbacks: Feedbacks;
}

interface GiveCheckoutFeedback extends Command<typeof GIVE_CHECKOUT_FEEDBACK>, GiveCheckoutFeedbackPayload {}

interface GiveCheckoutFeedbackFunction {
  (payload: GiveCheckoutFeedbackPayload): GiveCheckoutFeedback;
}

const giveCheckoutFeedback: GiveCheckoutFeedbackFunction = (payload) => ({
  ...command({ name: GIVE_CHECKOUT_FEEDBACK }),
  ...payload,
});

export type { GiveCheckoutFeedback };
export { GIVE_CHECKOUT_FEEDBACK, giveCheckoutFeedback };
