import { command, Command } from "@lookiero/messaging";

const BOOK_CHECKOUT_BOOKING_FOR_CHECKOUT_ITEM = "book_checkout_booking_for_checkout_item";

interface BookCheckoutBookingForCheckoutItemPayload {
  readonly aggregateId: string;
  readonly checkoutItemId: string;
}

interface BookCheckoutBookingForCheckoutItem
  extends Command<typeof BOOK_CHECKOUT_BOOKING_FOR_CHECKOUT_ITEM>,
    BookCheckoutBookingForCheckoutItemPayload {}

interface BookCheckoutBookingForCheckoutItemFunction {
  (payload: BookCheckoutBookingForCheckoutItemPayload): BookCheckoutBookingForCheckoutItem;
}

const bookCheckoutBookingForCheckoutItem: BookCheckoutBookingForCheckoutItemFunction = ({
  aggregateId,
  ...payload
}) => ({
  ...command({ aggregateId, name: BOOK_CHECKOUT_BOOKING_FOR_CHECKOUT_ITEM }),
  ...payload,
});

export type { BookCheckoutBookingForCheckoutItem };
export { BOOK_CHECKOUT_BOOKING_FOR_CHECKOUT_ITEM, bookCheckoutBookingForCheckoutItem };
