import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import {
  IsSizeChangeEnabledByCheckoutIdProjection,
  viewIsSizeChangeEnabledByCheckoutId,
} from "../../../../projection/checkout/viewIsSizeChangeEnabledByCheckoutId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewIsSizeChangeEnabledByCheckoutIdFunctionArgs {
  readonly checkoutId: string | undefined;
}

interface UseViewIsSizeChangeEnabledByCheckoutIdFunction {
  (
    args: UseViewIsSizeChangeEnabledByCheckoutIdFunctionArgs,
  ): UseQueryFunctionResult<IsSizeChangeEnabledByCheckoutIdProjection>;
}

const useViewIsSizeChangeEnabledByCheckoutId: UseViewIsSizeChangeEnabledByCheckoutIdFunction = ({ checkoutId }) =>
  useQuery({
    query: viewIsSizeChangeEnabledByCheckoutId({ checkoutId: checkoutId as string }),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(checkoutId),
    },
  });

export { useViewIsSizeChangeEnabledByCheckoutId };
