import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isNotificationCreated } from "../../../domain/notification/model/notificationCreated";
import { isNotificationRemoved } from "../../../domain/notification/model/notificationRemoved";
import { listNotifications, ListNotificationsResult } from "../listNotifications";

interface UseListNotificationsFunctionArgs {
  readonly contextId: string;
}

interface UseListNotificationsFunction {
  (args: UseListNotificationsFunctionArgs): UseQueryFunctionResult<ListNotificationsResult>;
}

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isNotificationCreated(event) || isNotificationRemoved(event);

const useListNotifications: UseListNotificationsFunction = ({ contextId }) =>
  useQuery({
    query: listNotifications(),
    contextId,
    invalidation: shouldInvalidate,
    options: { refetchOnMount: "always", staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useListNotifications };
