import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import invariant from "tiny-invariant";
import { StartCheckout } from "../command/startCheckout";
import { SubmitCheckout } from "../command/submitCheckout";
import { checkoutStarted } from "./checkoutStarted";
import { checkoutSubmitted } from "./checkoutSubmitted";

enum CheckoutStatus {
  AVAILABLE = "AVAILABLE",
  NOTIFIED = "NOTIFIED",
  STARTED = "STARTED",
  SUBMITTED = "SUBMITTED",
  COMPLETED = "COMPLETED",
}

interface Checkout extends AggregateRoot {
  readonly status: CheckoutStatus;
  readonly customerId: string;
  readonly boxId: string;
  readonly checkoutBookingId: string;
  readonly expiresOn: Date;
}

const startCheckoutHandler: CommandHandlerFunction<StartCheckout, Checkout> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;

    invariant(aggregateRoot.status !== CheckoutStatus.STARTED, "Checkout is already started");
    invariant(aggregateRoot.status !== CheckoutStatus.SUBMITTED, "Checkout is already submitted");
    invariant(aggregateRoot.status !== CheckoutStatus.COMPLETED, "Checkout is already completed");

    return {
      ...aggregateRoot,
      status: CheckoutStatus.STARTED,
      domainEvents: [checkoutStarted({ aggregateId })],
    };
  };

const submitCheckoutHandler: CommandHandlerFunction<SubmitCheckout, Checkout> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;

    invariant(aggregateRoot.status !== CheckoutStatus.COMPLETED, "Checkout is already completed");

    return {
      ...aggregateRoot,
      status: CheckoutStatus.SUBMITTED,
      domainEvents: [checkoutSubmitted({ aggregateId })],
    };
  };

export type { Checkout };
export { CheckoutStatus, startCheckoutHandler, submitCheckoutHandler };
