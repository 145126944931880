import { Platform, StyleSheet } from "react-native";
import { HEADER_HEIGHT } from "../../../../templates/header/Header.style";

const style = StyleSheet.create({
  header: {
    ...Platform.select({
      web: {
        position: "sticky",
        top: 0,
        zIndex: 10,
        height: HEADER_HEIGHT,
      },
      native: {
        position: "relative",
        height: HEADER_HEIGHT,
      },
    }),
  },
});

export { style };
