import { Theme } from "@lookiero/aurora";
import { StyleSheet } from "react-native";

const { space10, layoutMaxWidth } = Theme.get();
const rowLargeMaxWidth = layoutMaxWidth - 2 * space10;

const style = StyleSheet.create({
  row: {
    alignItems: "center",
    flexWrap: "wrap",
    marginHorizontal: "auto",
    maxWidth: layoutMaxWidth,
    width: "100%",
  },
  rowLarge: {
    maxWidth: rowLargeMaxWidth,
  },
});

export { style };
