import React, { createContext, FC, ReactNode, useContext } from "react";
import invariant from "tiny-invariant";
import { Kameleoon } from "../Kameleoon";

const KameleoonContext = createContext<Kameleoon | undefined>(null as unknown as Kameleoon);

interface KameleoonContextProviderProps {
  readonly kameleoon?: Kameleoon;
  readonly children: ReactNode;
}

const KameleoonProvider: FC<KameleoonContextProviderProps> = ({
  kameleoon,
  children,
}: KameleoonContextProviderProps) => (
  <KameleoonContext.Provider value={kameleoon}>{children}</KameleoonContext.Provider>
);

const useKameleoon = (): Kameleoon | undefined => {
  const kameleoon = useContext<Kameleoon | undefined>(KameleoonContext);

  invariant(
    kameleoon !== null,
    "Your are trying to use the useKameleoon hook without wrapping your app with the <KameleoonProvider>.",
  );

  return kameleoon;
};

export { useKameleoon, KameleoonProvider };
