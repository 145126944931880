import loadjs from "loadjs";
import { Logger } from "../../shared/logging/Logger";
import { Kameleoon, KameleoonAPI, AssignedVariantByExperimentIdFunction, TrackConversionFunction } from "./Kameleoon";

declare global {
  interface Window {
    readonly Kameleoon: KameleoonAPI;
    readonly Cypress: unknown;
  }
}

interface AsyncKameleoonFunctionArgs {
  readonly siteCode: string;
  readonly logger: Logger;
}

interface AsyncKameleoonFunction {
  (args: AsyncKameleoonFunctionArgs): Promise<Kameleoon>;
}

let instance: Kameleoon;

const asyncKameleoon: AsyncKameleoonFunction = async ({ siteCode, logger }) => {
  if (instance) {
    return instance;
  }

  const init = async () => {
    if (window.Kameleoon) {
      return;
    }

    try {
      await loadjs(`//${siteCode}.kameleoon.eu/kameleoon.js`, { returnPromise: true });
    } catch (error) {
      logger.captureException(error as Error);
    }
  };

  const assignedVariantByExperimentId: AssignedVariantByExperimentIdFunction = ({ experimentId }) => {
    let variation = undefined;

    if (window.Kameleoon && !window.Cypress) {
      const experiment = window.Kameleoon.API.Experiments.getById(experimentId);

      if (experiment && experiment.associatedVariation) {
        variation = experiment.associatedVariation;
      }
    }

    return variation;
  };

  const trackConversion: TrackConversionFunction = ({ goalId }) => {
    if (window.Kameleoon) {
      window.Kameleoon.API.Goals.processConversion(goalId);
    }
  };

  instance = {
    assignedVariantByExperimentId,
    trackConversion,
  };

  await init();

  return instance;
};

export { asyncKameleoon };
export type { AsyncKameleoonFunction };
