// TODO properly export MessagingRoot from @lookiero/messaging-react
import { I18n } from "@lookiero/i18n-react";
import { MessagingRoot } from "@lookiero/messaging-react/bootstrap";
import React, { ComponentType, useCallback } from "react";
import { Platform } from "react-native";
import { useRoutes as reactRouterUseRoutes } from "react-router-native";
import { Customer } from "../../projection/shared/customer";
import { Order } from "../../projection/shared/order";
import { Subscription } from "../../projection/shared/subscription";
import { sentryLogger, SentryEnvironment } from "../../shared/logging/SentryLogger";
import { sentryLoggerHOC } from "../../shared/logging/SentryLoggerHOC";
import { KameleoonEnvironment } from "../testing/kameleoonEnvironment";
import { Layout } from "./components/layouts/layout/Layout";
import { Routing } from "./routing/Routing";

interface RootFunctionArgs {
  readonly Messaging: MessagingRoot;
  readonly I18n: I18n;
  readonly development?: boolean;
  readonly sentry: () => SentryEnvironment;
  readonly getAuthToken: () => Promise<string>;
  readonly kameleoon: () => KameleoonEnvironment;
}

interface RootFunction {
  (args: RootFunctionArgs): ComponentType<RootProps>;
}

interface RootProps {
  readonly basePath: string;
  readonly locale?: string;
  readonly customer: Customer | undefined;
  readonly order: Order | undefined;
  readonly subscription: Subscription | undefined;
  readonly layout: Layout;
  readonly onNotAccessible: () => void;
  readonly onCheckoutSubmitted?: () => void;
  readonly useRedirect: () => Record<string, string>;
  readonly useRoutes?: typeof reactRouterUseRoutes;
}

const root: RootFunction = ({ Messaging, I18n, getAuthToken, development, sentry, kameleoon: kameleoonConfig }) => {
  const logger = sentryLogger(sentry);
  const kameleoon = kameleoonConfig();

  // eslint-disable-next-line react/display-name, react/prop-types
  const Root = ({
    basePath,
    locale = "en",
    customer,
    order,
    subscription,
    layout,
    onNotAccessible,
    onCheckoutSubmitted,
    useRedirect,
    useRoutes = reactRouterUseRoutes,
  }: RootProps) => {
    const handleOnI18nError = useCallback((error: Error) => logger.captureException(error), []);

    return (
      <Messaging includeReactQueryDevTools={Platform.OS === "web"}>
        <Routing
          I18n={I18n}
          basePath={basePath}
          customer={customer}
          getAuthToken={getAuthToken}
          kameleoon={kameleoon}
          layout={layout}
          locale={locale}
          order={order}
          subscription={subscription}
          useRedirect={useRedirect}
          useRoutes={useRoutes}
          onCheckoutSubmitted={onCheckoutSubmitted}
          onI18nError={development ? undefined : handleOnI18nError}
          onNotAccessible={onNotAccessible}
        />
      </Messaging>
    );
  };

  return sentryLoggerHOC({ logger })(Root);
};

export type { RootProps };
export { root };
