import { command, Command } from "@lookiero/messaging";

const START_CHECKOUT = "start_checkout";

interface StartCheckoutPayload {
  readonly aggregateId: string;
}

interface StartCheckout extends Command<typeof START_CHECKOUT>, StartCheckoutPayload {}

interface StartCheckoutFunction {
  (payload: StartCheckoutPayload): StartCheckout;
}

const startCheckout: StartCheckoutFunction = ({ aggregateId }) => command({ aggregateId, name: START_CHECKOUT });

export type { StartCheckout };
export { START_CHECKOUT, startCheckout };
