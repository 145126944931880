import React, { FC } from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Column } from "../../../../../ui/components/layouts/column/Column";
import { Row } from "../../../../../ui/components/layouts/row/Row";
import { Stack } from "../../../../../ui/components/layouts/stack/Stack";
import { useScreenSize } from "../../../../../ui/hooks/useScreenSize";
import { ToastNotificationProjection } from "../../../../projection/notification/notification";
import { ToastNotificationItem } from "./ToastNotificationItem";
import { style } from "./ToastNotifications.style";

const NOTIFICATIONS_AUTOHIDE_TIMEOUT = 10000;

interface ToastNotificationsProps {
  readonly notifications?: ToastNotificationProjection[];
  readonly onRemove: (notificationId: string) => void;
  readonly autoHideTimeout?: number;
}

const ToastNotifications: FC<ToastNotificationsProps> = ({
  notifications = [],
  onRemove,
  autoHideTimeout = NOTIFICATIONS_AUTOHIDE_TIMEOUT,
}) => {
  const { top: safeAreaInsetTop } = useSafeAreaInsets();
  const screenSize = useScreenSize();
  const isSmallDevice = screenSize === "S";

  return (
    <View pointerEvents="box-none" style={style.notifications} testID="toast-notifications">
      <Row style={[style.row, isSmallDevice && style.rowSmall, { paddingTop: safeAreaInsetTop }]}>
        <Column>
          <Stack>
            {notifications.map((notification) => (
              <ToastNotificationItem
                key={notification.id}
                autoHideTimeout={autoHideTimeout}
                notification={notification}
                style={{ toast: style.toast }}
                testID={notification.id}
                onRemove={onRemove}
              />
            ))}
          </Stack>
        </Column>
      </Row>
    </View>
  );
};

export { ToastNotifications };
