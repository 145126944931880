import { Portal } from "@gorhom/portal";
import { ButtonIcon, Theme, useBack } from "@lookiero/aurora";
import { animated, useTransition } from "@react-spring/native";
import React, { FC, ReactNode, useCallback, useMemo, useState } from "react";
import {
  Dimensions,
  LayoutChangeEvent,
  Platform,
  StyleProp,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context";
import { ScreenSize, useScreenSize } from "../../../hooks/useScreenSize";
import { Row } from "../row/Row";
import { Layout, Sticky } from "../sticky/Sticky";
import { style } from "./Modal.style";

const { space8, space10 } = Theme.get();

const TRANSITION_MODAL_SCALE = 0.9;
const DEFAULT_SAFEAREA_INSET_TOP = space10;
const MODAL_DESKTOP_VERTICAL_PADDING = space10;

type ColumnSize = "1/4" | "1/3" | "1/2" | "2/3";

type ModalStyle = "overlay" | "row" | "modal" | "header";

interface ModalProps {
  readonly children: ReactNode;
  readonly visible: boolean;
  readonly onClose: () => void;
  readonly showCloseButton?: boolean;
  readonly header?: ReactNode;
  readonly footer?: ReactNode;
  readonly portalHostName?: string;
  readonly scroll?: boolean;
  readonly size?: Partial<Record<ScreenSize, ColumnSize>>;
  readonly style?: Partial<Record<ModalStyle, StyleProp<ViewStyle>>>;
  readonly testID?: string;
}

const Modal: FC<ModalProps> = ({
  children,
  visible,
  header,
  footer,
  portalHostName,
  onClose,
  showCloseButton = false,
  size = { M: "2/3", L: "1/3" },
  style: customStyle,
  scroll = false,
  testID = "modal",
}) => {
  const { height: screenHeight } = Dimensions.get(Platform.OS === "web" ? "window" : "screen");
  const { top: safeAreaInsetTop, bottom: safeAreaInsetBottom } = useSafeAreaInsets();
  const screenSize = useScreenSize();
  const isSmallDevice = screenSize === "S";
  const columnSizeForScreenSize = size[screenSize];
  // On Android safeAreaInsetTop won't return the actual value.
  const screenSizeWithoutInsets =
    screenHeight - (Platform.OS === "android" ? DEFAULT_SAFEAREA_INSET_TOP : safeAreaInsetTop);
  const modalMaxHeight = screenSizeWithoutInsets - (!isSmallDevice ? MODAL_DESKTOP_VERTICAL_PADDING : 0);

  const handleHardwareBackPress = useCallback(() => {
    if (!visible) {
      return;
    }

    onClose();

    return true; // The event will not be bubbled up
  }, [onClose, visible]);
  useBack(handleHardwareBackPress);

  const [modalHeight, setModalHeight] = useState<number>();
  const handleOnModalLayout = useCallback(
    ({
      nativeEvent: {
        layout: { height },
      },
    }: LayoutChangeEvent) => setModalHeight(height),
    [],
  );

  const [footerHeight, setFooterHeight] = useState<number>(0);
  const handleOnFooterLayout = useCallback(({ height }: Layout) => setFooterHeight(height), []);

  const transitions = useTransition(visible, {
    from: isSmallDevice
      ? {
          modalOpacity: 1,
          overlayOpacity: 0,
          modalTranslateY: (modalHeight || screenHeight) + safeAreaInsetBottom,
          modalScale: 1,
        }
      : { modalOpacity: 0, overlayOpacity: 0, modalTranslateY: 0, modalScale: TRANSITION_MODAL_SCALE },
    enter: { modalOpacity: 1, overlayOpacity: 1, modalTranslateY: 0, modalScale: 1 },
    leave: isSmallDevice
      ? {
          modalOpacity: 1,
          overlayOpacity: 0,
          modalTranslateY: (modalHeight || screenHeight) + safeAreaInsetBottom,
          modalScale: 1,
        }
      : { modalOpacity: 0, overlayOpacity: 0, modalTranslateY: 0, modalScale: TRANSITION_MODAL_SCALE },
    unique: true,
  });

  const ModalContentView = useMemo(() => (scroll ? KeyboardAwareScrollView : View), [scroll]);

  return (
    <Portal hostName={portalHostName}>
      {transitions(
        ({ modalOpacity, overlayOpacity, modalTranslateY, modalScale }, item) =>
          item && (
            <View pointerEvents={visible ? "auto" : "none"} style={style.container} testID={testID}>
              <TouchableWithoutFeedback onPress={visible ? onClose : undefined}>
                <animated.View
                  style={[
                    style.overlay,
                    { opacity: overlayOpacity },
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    customStyle?.overlay,
                  ]}
                />
              </TouchableWithoutFeedback>
              <SafeAreaView edges={["right", "top", "left"]} pointerEvents="box-none" style={style.safeArea}>
                <Row pointerEvents="box-none" style={[style.row, isSmallDevice && style.rowSmall, customStyle?.row]}>
                  <animated.View
                    pointerEvents={visible ? "auto" : "none"}
                    style={[
                      style.modal,
                      !isSmallDevice ? style.modalLarge : undefined,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      columnSizeForScreenSize && style[columnSizeForScreenSize],
                      {
                        opacity: modalOpacity,
                        transform: [{ translateY: modalTranslateY }, { scale: modalScale }],
                        maxHeight: modalMaxHeight,
                      },
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      customStyle?.modal,
                    ]}
                    onLayout={handleOnModalLayout}
                  >
                    {(header || showCloseButton) && (
                      <View style={[style.header, customStyle?.header]}>
                        {showCloseButton && (
                          <ButtonIcon name="close" style={style.closeButton} testID="button-icon" onPress={onClose} />
                        )}
                        <View>{header}</View>
                      </View>
                    )}

                    <ModalContentView keyboardShouldPersistTaps="handled">
                      <View
                        style={{
                          paddingBottom:
                            safeAreaInsetBottom +
                            (Platform.OS !== "ios" ? space8 : 0) +
                            (Platform.OS !== "web" ? footerHeight : 0),
                        }}
                      >
                        {children}
                      </View>
                    </ModalContentView>

                    {footer && (
                      <Sticky style={style.stickyFooter} onLayout={handleOnFooterLayout}>
                        {footer}
                      </Sticky>
                    )}
                  </animated.View>
                </Row>
              </SafeAreaView>
            </View>
          ),
      )}
    </Portal>
  );
};

export type { ColumnSize };
export { Modal };
