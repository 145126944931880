import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

type IsCheckoutEnabledByCustomerIdProjection = boolean;

const VIEW_IS_CHECKOUT_ENABLED_BY_CUSTOMER_ID = "view_is_checkout_enabled_by_customer_id";

interface ViewIsCheckoutEnabledByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewIsCheckoutEnabledByCustomerId
  extends Query<typeof VIEW_IS_CHECKOUT_ENABLED_BY_CUSTOMER_ID>,
    ViewIsCheckoutEnabledByCustomerIdPayload {}

interface ViewIsCheckoutEnabledByCustomerIdFunction {
  (payload: ViewIsCheckoutEnabledByCustomerIdPayload): ViewIsCheckoutEnabledByCustomerId;
}

const viewIsCheckoutEnabledByCustomerId: ViewIsCheckoutEnabledByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_IS_CHECKOUT_ENABLED_BY_CUSTOMER_ID }),
  ...payload,
});

interface IsCheckoutEnabledByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface IsCheckoutEnabledByCustomerIdView {
  (args: IsCheckoutEnabledByCustomerIdViewArgs): Promise<IsCheckoutEnabledByCustomerIdProjection>;
}

interface ViewIsCheckoutEnabledByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: IsCheckoutEnabledByCustomerIdView;
}

const viewIsCheckoutEnabledByCustomerIdHandler: QueryHandlerFunction<
  ViewIsCheckoutEnabledByCustomerId,
  IsCheckoutEnabledByCustomerIdProjection,
  ViewIsCheckoutEnabledByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { IsCheckoutEnabledByCustomerIdProjection, IsCheckoutEnabledByCustomerIdView };
export {
  VIEW_IS_CHECKOUT_ENABLED_BY_CUSTOMER_ID,
  viewIsCheckoutEnabledByCustomerId,
  viewIsCheckoutEnabledByCustomerIdHandler,
};
