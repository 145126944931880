import { EndpointFunction } from "@lookiero/i18n";
import { Country } from "../../../projection/shared/country";
import { Locale } from "../../../projection/shared/locale";

interface TranslationEndpointFunctionArgs {
  readonly translationsUrl: string;
  readonly translationsApiKey: string;
}
interface TranslationEndpointFunction {
  (args: TranslationEndpointFunctionArgs): EndpointFunction;
}

const translationEndpoint: TranslationEndpointFunction =
  ({ translationsUrl, translationsApiKey }) =>
  (locale) =>
    `${translationsUrl}/${locale}?key=${translationsApiKey}&no-folding=true`;

type Project = "user-area-front" | "inventory-catalog" | "checkout";

const COUNTRY: Record<Locale, Country> = {
  [Locale.ES]: Country.ES,
  [Locale.FR]: Country.FR,
  [Locale.EN]: Country.GB,
  [Locale.IT]: Country.IT,
  [Locale.PT]: Country.PT,
  [Locale.DE]: Country.DE,
  [Locale.AT]: Country.AT,
  [Locale.NL]: Country.NL,
  [Locale.SE]: Country.SE,
};

interface TranslationExternalEndpointFunctionArgs {
  readonly translationsUrl: string;
  readonly projects: [project: Project, filter?: string][];
}
interface TranslationExternalEndpointFunction {
  (args: TranslationExternalEndpointFunctionArgs): EndpointFunction;
}

const translationExternalEndpoint: TranslationExternalEndpointFunction =
  ({ translationsUrl, projects }) =>
  (locale) => {
    const projectsQueryParam = projects
      .map(([project, filter]) => `projectFilter=${project}${filter ? `:${filter}` : ""}`)
      .join("&");

    return `${translationsUrl}/${locale}/${COUNTRY[locale as Locale]}?${projectsQueryParam}`;
  };

export { translationEndpoint, translationExternalEndpoint };
