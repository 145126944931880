import React, { FC, ReactNode, useCallback } from "react";
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from "react-native";
import { style } from "./Sticky.style";

interface Layout {
  readonly width: number;
  readonly height: number;
}

interface StickyProps {
  readonly children: ReactNode;
  readonly style?: StyleProp<ViewStyle>;
  readonly onLayout?: ({ width, height }: Layout) => void;
}

const Sticky: FC<StickyProps> = ({ children, style: customStyle, onLayout }) => {
  const handleOnLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width, height },
      },
    }: LayoutChangeEvent) => onLayout?.({ width, height }),
    [onLayout],
  );

  return (
    <View style={[style.sticky, customStyle]} onLayout={handleOnLayout}>
      {children}
    </View>
  );
};

export type { Layout };
export { Sticky };
