import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import invariant from "tiny-invariant";
import { CheckoutItem } from "../../../../domain/checkoutItem/model/checkoutItem";
import {
  CheckoutItemsGetFunction,
  CheckoutItemsSaveFunction,
} from "../../../../domain/checkoutItem/model/checkoutItems";
import {
  viewCheckoutItemById,
  ViewCheckoutItemById,
  ViewCheckoutItemByIdResult,
} from "../../../../projection/checkoutItem/viewCheckoutItemById";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpCheckoutItemsKeep } from "./httpCheckoutItemsKeep";
import { httpCheckoutItemsReplace } from "./httpCheckoutItemsReplace";
import { httpCheckoutItemsReset } from "./httpCheckoutItemsReset";
import { httpCheckoutItemsReturn } from "./httpCheckoutItemsReturn";

interface ToDomainFunction {
  (checkout: ViewCheckoutItemByIdResult): CheckoutItem | never;
}

const toDomain: ToDomainFunction = (checkoutItem) => {
  invariant(checkoutItem, "No checkoutItem found!");

  return {
    id: checkoutItem.id,
    status: checkoutItem.status,
    price: checkoutItem.price,
    feedbacks: checkoutItem.feedbacks,
    replacedForId: checkoutItem.replacedFor?.id,
    aggregateId: checkoutItem.id,
    domainEvents: [],
  };
};

interface HttpCheckoutItemsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpCheckoutItemsGetFunction extends CheckoutItemsGetFunction<HttpCheckoutItemsGetFunctionArgs> {}

const getCheckoutItem: HttpCheckoutItemsGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(
      await queryBus<ViewCheckoutItemById, ViewCheckoutItemByIdResult>(
        viewCheckoutItemById({ checkoutItemId: aggregateId }),
      ),
    );

interface HttpCheckoutItemsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutItemsSaveFunction extends CheckoutItemsSaveFunction<HttpCheckoutItemsSaveFunctionArgs> {}

const saveCheckoutItem: HttpCheckoutItemsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await Promise.all([
      httpCheckoutItemsKeep({ httpPost })(aggregateRoot),
      httpCheckoutItemsReturn({ httpPost })(aggregateRoot),
      httpCheckoutItemsReplace({ httpPost })(aggregateRoot),
      httpCheckoutItemsReset({ httpPost })(aggregateRoot),
    ]);
  };

export type { HttpCheckoutItemsSaveFunction };
export { getCheckoutItem, saveCheckoutItem };
