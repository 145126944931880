import { CheckoutStatus } from "../../../domain/checkout/model/checkout";
import { CheckoutProjection } from "../../../projection/checkout/checkout";
import { CheckoutItemProjection } from "../../../projection/checkoutItem/checkoutItem";

interface CheckoutDto {
  readonly id: string;
  readonly customerId: string;
  readonly boxId: string;
  readonly checkoutBookingId: string;
  readonly status: CheckoutStatus;
  readonly expiresOn: string;
  readonly items: CheckoutItemProjection[];
}

interface ToCheckoutProjectionFunction {
  (checkoutDto: CheckoutDto): CheckoutProjection;
}

const toCheckoutProjection: ToCheckoutProjectionFunction = (checkoutDto) => ({
  ...checkoutDto,
  expiresOn: new Date(checkoutDto.expiresOn),
});

export type { CheckoutDto };
export { toCheckoutProjection };
