import { DomainEvent, MessageName } from "@lookiero/messaging";
import {
  CheckoutItemReplaced,
  CHECKOUT_ITEM_REPLACED,
} from "../../../../domain/checkoutItem/model/checkoutItemReplaced";
import { HttpCheckoutItemsSaveFunction } from "./httpCheckoutItems";

interface HttpCheckoutItemsReplaceFunction extends HttpCheckoutItemsSaveFunction {}

const isCheckoutItemReplaced = (event: DomainEvent<MessageName>): event is CheckoutItemReplaced =>
  event.name === CHECKOUT_ITEM_REPLACED;

const httpCheckoutItemsReplace: HttpCheckoutItemsReplaceFunction =
  ({ httpPost }) =>
  async ({ aggregateId, replacedForId, domainEvents }) => {
    const checkoutItemReplaced = domainEvents.find(isCheckoutItemReplaced);

    if (!checkoutItemReplaced) {
      return;
    }

    await httpPost({
      endpoint: "/replace-checkout-item",
      body: { checkoutItemId: aggregateId, replacedFor: replacedForId },
    });
  };

export { httpCheckoutItemsReplace };
