import { Theme } from "@lookiero/aurora";
import { StyleSheet } from "react-native";

const { space10 } = Theme.get();

const style = StyleSheet.create({
  buttonIconPlaceholder: {
    flexGrow: 0,
    height: space10,
    width: space10,
  },
});

export { style };
