import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import { blockCheckoutBooking as blockCheckoutBookingCommand } from "../../../../domain/checkoutBooking/command/blockCheckoutBooking";
import { Logger } from "../../../../shared/logging/Logger";
import { useCreateToastNotification } from "../../../../shared/notifications";
import { NotificationLevel } from "../../../../shared/notifications/domain/notification/model/notification";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";

interface BlockCheckoutBookingFunction {
  (): Promise<void>;
}

type UseBlockCheckoutBooking = [blockCheckoutBooking: BlockCheckoutBookingFunction, status: CommandStatus];

interface UseBlockCheckoutBookingFunctionArgs {
  readonly checkoutBookingId: string;
  readonly logger: Logger;
}

interface UseBlockCheckoutBookingFunction {
  (args: UseBlockCheckoutBookingFunctionArgs): UseBlockCheckoutBooking;
}

const useBlockCheckoutBooking: UseBlockCheckoutBookingFunction = ({ checkoutBookingId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const blockCheckoutBooking = useCallback(async () => {
    try {
      await commandBus(
        blockCheckoutBookingCommand({
          aggregateId: checkoutBookingId,
        }),
      );
    } catch (error) {
      logger.captureException(error as Error);
      createNotification({
        level: NotificationLevel.ERROR,
        bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
      });

      throw error;
    }
  }, [checkoutBookingId, commandBus, createNotification, logger]);

  return [blockCheckoutBooking, status];
};

export { useBlockCheckoutBooking };
