import { Theme } from "@lookiero/aurora";
import { Platform, StyleSheet, ViewStyle } from "react-native";

const { colorBgBase, shadowColor, elevationLayerL, shadowOffset, shadowOpacityM, shadowRadius, space3, space6 } =
  Theme.get();

const style = StyleSheet.create({
  sticky: {
    backgroundColor: colorBgBase,
    bottom: 0,
    elevation: elevationLayerL,
    paddingHorizontal: space6,
    paddingVertical: space3,
    shadowColor: shadowColor,
    shadowOffset: shadowOffset,
    shadowOpacity: shadowOpacityM,
    shadowRadius: shadowRadius,
    width: "100%",
    ...(Platform.select({
      web: { position: "sticky" },
      ios: { position: "absolute" },
      android: { position: "absolute" },
    }) as ViewStyle),
    zIndex: 0,
  },
});

export { style };
